import { MutationTree, ActionTree, GetterTree } from 'vuex';
import Vue from 'vue';
import { AxiosError } from 'axios';
import { IMemberships, IRoles, IUsers } from '@/shared/types';

import Axios from '@/plugins/axios';

import { List } from '@/util/list.type';

export type User = {
    details: Partial<IUsers>;
    memberships: IMemberships[];
};

export type UserState = {
    user: User;
};

/**
 * Find a membership by teamId
 * @param teamId Team ID to find membership for
 */
const currentMembership = (teamId: string) => (m: IMemberships) => m.teamId === teamId;

const fetchMemberships = async (limit: number, page = 1) => {
    const { data } = await Axios.get<List<IMemberships>>('/users/me/memberships', {
        params: {
            limit,
            page,
        },
    });

    return data;
};

/**
 * Get user memberships recursively
 * @param page Page to fetch, defaults to `1`
 */
const getMemberships = async () => {
    // To store all memberships
    const allMemberships: IMemberships[] = [];

    // Data params
    let total = 0;
    const limit = 10;

    try {
        // Get first memberships
        const memberships = await fetchMemberships(limit);

        allMemberships.push(...memberships.data);
        total = memberships.total;

        // More memberships need to be fetched
        if (allMemberships.length !== total) {
            // Get all pending pages to fetch
            const lastPage = Math.ceil(total / limit);
            // Get pending pages from 2 to `lastPage`
            const pagesPending = [...Array(lastPage + 1).keys()].slice(2);

            // Fetch pending pages
            await Promise.all(
                pagesPending.map(async (page: number) => {
                    // Fetch memberships on page
                    const memberships = await fetchMemberships(limit, page);

                    // Append new memberhips to main array
                    allMemberships.push(...memberships.data);
                }),
            );
        }
    } catch (error: any) {
        const e = error as AxiosError;

        console.warn("There was an error loading user's memberships", e);
    }

    // Return results
    return allMemberships;
};

export const namespaced = true;

export const state = (): UserState => ({
    user: {
        details: {},
        memberships: [],
    },
});

export const mutations: MutationTree<UserState> = {
    setUserDetails(state: UserState, { details, memberships }: { details?: IUsers; memberships?: List<IMemberships> }) {
        if (details) Vue.set(state.user, 'details', details);
        if (memberships) Vue.set(state.user, 'memberships', memberships);
    },
    setPermissions(state: UserState, role: IRoles) {
        // Find memberships with this role
        const membershipIndex = state.user.memberships.findIndex((m) => m.roleId === role.id);

        // If no membership is found, log and return
        if (typeof membershipIndex !== 'number' || membershipIndex === -1) {
            console.warn('No membership for `permissions` update', role);
            return;
        }

        // Update the membership role with new permissions
        Vue.set(state.user.memberships[membershipIndex], 'role', role);
    },
    setRole(state: UserState, membership: IMemberships) {
        // Find membership
        const membershipIndex = state.user.memberships?.findIndex((m) => m.id === membership.id);

        // If no membership is found, log and return
        if (typeof membershipIndex !== 'number' || membershipIndex === -1) {
            console.warn('No membership for `permissions` update', membership);
            return;
        }

        // Build new membership
        const newMembership = Object.assign<Partial<IMemberships>, IMemberships, IMemberships>({}, state.user.memberships[membershipIndex], membership);

        // Create new array from existing memberships array and splice in new membership
        const newArray = [...(state.user.memberships ?? [])];
        newArray.splice(membershipIndex, 1, newMembership);

        // Update the membership role
        Vue.set(state.user.memberships, 'data', newArray);
    },
};

export const actions: ActionTree<UserState, any> = {
    async updateUser({ commit, rootState }) {
        // Check for token
        if (!(rootState as any).auth.isAuthenticated) {
            console.warn('Skipping `updateUser()`, no auth token');
            return;
        }

        // Fetch user data
        const [details, memberships]: [IUsers, IMemberships[]] = await Promise.all([Axios.get('/users/me').then((r) => r.data), getMemberships()]);

        // Log user details
        console.log('Setting user details', details, memberships);
        commit('setUserDetails', { details, memberships });
    },
    setUserDetails({ commit }, { details, memberships }) {
        console.log('Setting user details', details, memberships);
        commit('setUserDetails', { details, memberships });
    },
};

export const getters: GetterTree<UserState, any> = {
    user: (state): User => state.user,
    details: (state) => state.user.details,
    // FIXME: Ensure the bottom 2 can be done while relying on team store
    permissions: (state, _getters, _rootState, rootGetters) =>
        state.user?.memberships?.find(currentMembership(rootGetters['team/team'].id))?.role!.permissions ?? [],
    role: (state, _getters, _rootState, rootGetters) => state.user?.memberships?.find(currentMembership(rootGetters['team/team'].id))?.role,
    isOwner: (_state, getters) => {
        const { role } = getters;
        return (role?.name === 'Owner' || role?.name === 'Reseller Owner') && !role.teamId;
    },
    // Check for team and reseller being settled on membership
    parsedMemberships: (state, _getters, _rootState, rootGetters) =>
        state.user?.memberships?.filter((m) => {
            if (!m.team?.id) {
                console.error('Checking for relevant membership failed, no team attached', m);
                return false;
            }
            if (!rootGetters['reseller/reseller']?.id) {
                console.error("Failed checking relevant memberships, reseller isn't available yet");
                return false;
            }
            return true;
        }) ?? [],
    // Find memberships that are within current reseller or are a reseller themselves
    currentResellerMemberships: (_state, getters, _rootState, rootGetters) =>
        getters.parsedMemberships.filter(
            (m: IMemberships) =>
                // Team is part of current reseller
                m.team?.resellerId === rootGetters['reseller/reseller']?.id ||
                // Include root reseller if present and we are on that domain currently
                (m.team?.resellerId === null && m.team?.ofReseller?.domain === rootGetters['reseller/domain']),
        ) ?? [],
    // Find any reseller memberships
    resellerMemberships: (_state, getters, _rootState) =>
        getters.parsedMemberships.filter(
            (m: IMemberships) =>
                // Team is reseller
                !!m.team?.ofResellerId &&
                // Exclude memberships already present in current reseller memberships
                !getters.currentResellerMemberships.find((i: IMemberships) => i.id === m.id),
        ) ?? [],
    // Find users preferred membership within membership array
    preferredMembership: (state) => state.user.memberships?.find((m) => m.id === state.user.details?.preferredMembershipId),
};
