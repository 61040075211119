export const MaximumTimeToLockValues: string[] = [
    '0',
    '15000',
    '30000',
    '60000',
    '120000',
    '300000',
    '600000',
    '1800000',
    '3600000',
];

export const ExternalZeroTouchCustomerLinked = 'linked_to_external_customer';
