enum BillingIssueType {
    /**
     * Team doesn't have a valid payment method, team must fix
     * by this point the team is not on the non-payment flow
     */
    INVALID_PAYMENT_METHOD = 'INVALID_PAYMENT_METHOD',
    /** Team has outstanding unpaid invoices, team must fix */
    UNPAID_INVOICES = 'UNPAID_INVOICES',
    /** Team has a cancelled Stripe subscription, team must fix  */
    CANCELLED_SUBSCRIPTION = 'CANCELLED_SUBSCRIPTION',
    /** Team has a broken subscription, reseller must fix */
    BROKEN_SUBSCRIPTION = 'BROKEN_SUBSCRIPTION',
    /** Team has an empty subscription, team must fix */
    EMPTY_SUBSCRIPTION = 'EMPTY_SUBSCRIPTION',
}

type BillingIssue = {
    /** Whether or not the billing alert should be shown for the team + user */
    showAlert: boolean;
    /** Whether the user has billing permissions or not */
    userHasBillingPermission: boolean;
    /** Type of alert - relates to the cause of the alert */
    type: BillingIssueType;
    /** Date in which the billing issue has been present */
    since?: Date;
    /** Days since the billing issue has been present */
    daysSince?: number;
    /** Whether this alert should be shown to all users, regardless of permissions */
    showToEveryone?: boolean;
    /** Whether a full-screen alert should also be shown */
    showFullScreen?: boolean;
    /** Whether the team is in read-only mode or not */
    teamIsReadOnly?: boolean;
    /** Whether the read-only reason is billing related or not */
    readOnlyDueToBilling?: boolean;
    /** Whether the non-payment flow has been paused or not */
    hasPausedNonPaymentFlow?: boolean;
};

export { BillingIssue, BillingIssueType };
