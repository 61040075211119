export default {
    bindingDeleted: {
        p1: 'This team is not currently bound to a valid enterprise for Android Enterprise management. Without a valid enterprise, devices, policies, and enrolment tokens are unmanageable. These pages have been hidden and can be accessed once the binding is complete.',
        p2: 'Immediate action is required to ensure uninterrupted access to all parts of the application. Please navigate to ',
        p3: 'the Android Enterprise Binding section below ',
        p4: 'and re-bind your team to a valid enterprise. Once re-enabled, you may re-save policies and create new enrolment tokens for device re-enrolment, and we will redeploy to your newly-bound enterprise accordingly.',
        p5: 'To regain access to the application, please reach out to the owners of the team to re-bind your team with a valid enterprise.',
    },
    link: {
        teamSettingsPage: 'Team Settings',
    },
};
