export default {
    title: 'Access tokens',
    item: 'token | tokens',
    text: 'This feature is for developers only. Your access tokens are like your password, and anyone with access to them can change your account settings, including billing information. Make sure you keep them safe!',
    addDialog: {
        title: 'Add API Access Token',
        titleERM: 'Add ERP Access Token',
    },
    viewDialog: {
        title: 'View {0} access token',
    },
    deleteDialog: {
        title: 'Delete {0} access token',
        text: {
            p1: 'Are you sure you want to delete your access token with',
            p2: 'name? This action is not reversible, and this access token will stop working immediately.',
        },
    },
    bulkDeleteDialog: {
        title: 'Delete access token | Delete access tokens',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'access token? This action is not reversible, and this token will stop working immediately. | access tokens? This action is not reversible, and these tokens will stop working immediately.',
        },
    },
    multiMatchToDelete: 'Delete 1 token | Delete {count} tokens',
    noData: 'You have not created any access tokens',
    addERMToken: 'Add ERP hooks token',
    addAPIToken: 'Add API Token',
    ermType: 'ERP hooks token',
    apiType: 'API Token',
};
