import { MutationTree, ActionTree, GetterTree } from 'vuex';
import Axios from '@/plugins/axios';
import Vue from 'vue';
import psl, { ParsedDomain } from 'psl';
import { IResellers, PlanFeatureFlags, IPlans } from '@/shared/types';
import Vuetify from '@/plugins/vuetify';
import { AxiosError } from 'axios';

import { PaginatedData } from '@/util/page-data.type';

export const namespaced = true;

export type ResellerState = {
    domain: string;
    reseller: Partial<IResellers>;
    plans: IPlans[];
    logoObjectURL: string | null;
    activeResellerBrokenSubscriptions: number;
};

export const state = (): ResellerState => ({
    domain: '',
    reseller: {},
    plans: [],
    logoObjectURL: null,
    activeResellerBrokenSubscriptions: 0,
});

export const mutations: MutationTree<ResellerState> = {
    setReseller(state: ResellerState, reseller: IResellers) {
        Vue.set(state, 'reseller', reseller);
    },
    updateReseller(state: ResellerState, reseller: IResellers) {
        if (reseller.id === state.reseller.id) {
            Vue.set(state, 'reseller', reseller);
        }
    },
    setDomain(state: ResellerState) {
        // Get the current app domain
        const URL = psl.parse(window.location.hostname);
        if (URL.error) throw new Error('Invalid domain');

        const { domain } = URL as ParsedDomain;

        Vue.set(state, 'domain', domain);
    },
    setPlans(state: ResellerState, plans: IPlans[]) {
        Vue.set(state, 'plans', plans);
    },
    setLogoObjectURL(state: ResellerState, url: string) {
        if (state.logoObjectURL) {
            URL.revokeObjectURL(state.logoObjectURL);
        }
        state.logoObjectURL = url;
    },
    setBrokenSubscriptions(state: ResellerState, amount?: number) {
        Vue.set(state, 'activeResellerBrokenSubscriptions', amount ?? 0);
    },
};

export const actions: ActionTree<ResellerState, any> = {
    async updateReseller({ commit, state, dispatch }, force = false) {
        // Get the current app domain
        const URL = psl.parse(window.location.hostname);
        if (URL.error) throw new Error('Invalid domain');

        const { domain } = URL as ParsedDomain;

        // If a reseller is set under the current domain, don't refresh unless forced
        if (!force && state.reseller?.id && state.domain === domain) {
            dispatch('setTheme');
            return state.reseller;
        }

        // Update domain
        commit('setDomain');

        // Get reseller
        const { data: reseller }: { data: IResellers } = await Axios.get('/resellers', {
            params: { domain: state.domain },
        });

        // Set reseller
        commit('setReseller', reseller);
        dispatch('setTheme');

        // Return reseller
        return reseller;
    },
    setTheme({ state, rootState, dispatch }) {
        const { plan } = rootState.team;

        if (plan?.featureFlags?.includes(PlanFeatureFlags.BRANDING)) {
            dispatch('team/setTheme', '', {
                root: true,
            });
        } else {
            // Update primary color on theme
            Vuetify.framework.theme.themes.light.primary =
                state.reseller?.tenant?.primaryColor ?? // Set a fallback incase no reseller is fetched, this indicates an error
                `#${new Array(6)
                    .fill(null)
                    .map(() => '0123456789ABCDEF'.split('')[Math.floor(Math.random() * 16)])
                    .join('')}`;
        }
    },
    async updatePlans({ commit, state, rootState, getters }) {
        // Can't fetch if user is not authenticated - gives 401
        if (!rootState.auth.isAuthenticated) return;

        if (!state.reseller.id || !state.reseller.stripeKeyCompact) {
            console.warn("Reseller doesn't have Stripe setup, can't fetch plans");
            return;
        }

        // To store all plans
        const allPlans: IPlans[] = [];

        // Data params
        let total = 0;
        const limit = 10;

        try {
            // Get current plans offered by reseller
            const { data: plans } = await Axios.get<PaginatedData<IPlans>>(`/resellers/${getters.activeReseller?.id ?? state.reseller.id}/billing/plans`, {
                params: {
                    limit,
                    page: 1,
                },
            });

            allPlans.push(...plans.data);
            total = plans.total;

            // More plans need to be fetched
            if (allPlans.length !== total) {
                // Get all pending pages to fetch
                const lastPage = Math.ceil(total / limit);
                // Get pending pages from 2 to `lastPage`
                const pagesPending = [...Array(lastPage + 1).keys()].slice(2);

                // Fetch pending pages
                await Promise.all(
                    pagesPending.map(async (page: number) => {
                        // Fetch plans on page
                        const { data } = await Axios.get<PaginatedData<IPlans>>(`/resellers/${state.reseller.id}/billing/plans`, {
                            params: {
                                limit,
                                page,
                            },
                        });

                        // Append new plans to main array
                        allPlans.push(...data.data);
                    }),
                );
            }

            console.log('commit all plans', allPlans);

            // Set plans in store
            commit('setPlans', allPlans);

            return plans;
        } catch (error: any) {
            const e = error as AxiosError;

            console.warn("Reseller's plans couldn't be loaded", e);
        }
    },
    async fetchLogo({ commit }) {
        const host = psl.parse(window.location.hostname);
        if (!host.error) {
            try {
                const { data } = await Axios.get(`/teams/logo?resellerDomain=${host.domain}`, { responseType: 'blob' });
                const objectURL = URL.createObjectURL(data);
                commit('setLogoObjectURL', objectURL);
            } catch (error) {
                console.error(error);
            }
        }
    },

    async fetchBrokenSubscriptions({ commit, getters }) {
        if (!getters.activeReseller?.id) return;

        // Fetch teams with broken subscriptions, if any
        try {
            const { data } = await Axios.get<{ count: number }>(`/resellers/${getters.activeReseller.id}/teams/broken-subscription-count`);

            commit('setBrokenSubscriptions', data.count);
        } catch (e) {
            console.warn('Error fetching broken subscriptions');
        }
    },
};

export const getters: GetterTree<ResellerState, any> = {
    domain: (state) => state.domain,
    /** Reseller meaning the reseller of this team (Mambo) */
    reseller: (state) => state.reseller,
    /** This reseller meaning this team as a posible reseller (Mambo, or other resellers), used for nested reseller actions */
    activeReseller: (_state, _getters, rootState, rootGetters) => {
        let activeReseller: IResellers | undefined;

        if (rootGetters['team/isReseller']) {
            activeReseller = (rootState as any).team?.team?.ofReseller;
        }

        return activeReseller;
    },
    activeResellerBrokenSubscriptions: (state) => state.activeResellerBrokenSubscriptions,
    color: (state) => state.reseller?.tenant?.primaryColor,
    plans: (state) => state.plans,
    /** Gives an array of feature flags that are in use by the reseller
     *  Meaning all these features are available for the team
     */
    availableFeatureFlags: (state) =>
        state.plans.reduce((acc, plan) => {
            const { featureFlags } = plan;

            // Only check active plans' feature flags
            if (plan.active && featureFlags?.length) {
                // Add any feature if its not there already
                acc = new Set([...acc, ...featureFlags]);
            }

            return acc;
        }, new Set<PlanFeatureFlags>()),
    logo: (state: ResellerState) => state.logoObjectURL,
};
