/**
 * Ensure the passed in function is only ever called once
 * @param fn Function to make onceable
 */
export default (fn: (...params: any[]) => void) => {
    // Initialise called as false
    let called = false;

    // Return a once callable function
    return (...params: any[]) => {
        // If not called then call function
        if (!called) {
            // Set called true to prevent calling again
            called = true;

            // Call function
            fn(...params);
        }
    };
};
