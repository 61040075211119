/**
 * Remote Control event type
 * - `KEY` used for a `keyboardEventDetails` payload
 * - `TAP` and `SWIPE` used for a `touchEventDetails` payload
 * - All other values used as standalone event without additional payload
 */
export enum RemoteControlEventType {
    BACK = 0,
    HOME,
    LOCK_SCREEN,
    NOTIFICATIONS,
    POWER_DIALOG,
    QUICK_SETTINGS,
    RECENTS,
    TAKE_SCREENSHOT,
    TOGGLE_SPLIT_SCREEN,
    TAP,
    SWIPE,
    KEY,
}

/**
 * Special button handling
 */
export enum KeyboardSpecialButton {
    BACKSPACE = 0,
    DELETE,
    ARROW_LEFT,
    ARROW_RIGHT,
    ARROW_UP,
    ARROW_DOWN,
    HOME,
    END,
    ENTER,
}
