/**
 * Define kiosk mode device orientation
 */
export enum KioskOrientation {
    AUTO = 'auto',
    PORTRAIT = 'portrait',
    LANDSCAPE = 'landscape',
}

/**
 * Define kiosk mode background type
 */
export enum KioskBackgroundType {
    COLOR = 'color',
    IMAGE = 'image',
}

/**
 * Define kiosk mode custom message position
 */
export enum KioskMessageLocation {
    TOP = 'top',
    BOTTOM = 'bottom',
}

/**
 * Define kiosk mode app layout type (Locked or device-editable)
 */
export enum KioskLayoutsConfigurationMode {
    MANUAL = 'manual',
    AUTO = 'automatic',
}
