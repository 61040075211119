export default {
    error: "There was an error getting the device's location, please try again.",
    locateEvery: 'You can locate a device every 5 minutes',
    locating: 'Locating device',
    locateAgain: 'You can locate again in',
    showingLocationAt: 'Showing location at',
    notLocatedBefore: 'You have not located this device before.',
    locationAt: 'Location at',
    noCompanion: 'Android device not ready to be located yet, please try again in a few minutes.',
    noLocationReceived: {
        p1: 'Request was sent but the device did not respond with a location within a timely manner. This may be due to:',
        item1: 'Device being offline',
        item2: 'Location services being disabled',
        item3: 'Resource pressure due to higher priority ongoing',
        p2: 'We may still receive the location if the device responds later. Please feel free to check back at another time, or initiate a new request.',
    },
};
