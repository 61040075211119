import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from '@/store';
import Axios from './axios';
import { AuthState } from '../store/auth';

const retryLogic = async (request: any) => {
    console.warn('RUNNING AXIOS REFRESH LOGIC');
    return store
        .dispatch('auth/refresh')
        .then(() => {
            // Set token after refresh
            const { tokens: { token } = {} } = store.state.auth as AuthState;

            // Set header
            Axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            request.response.config.headers.Authorization = `Bearer ${token}`;

            // Return
            return Promise.resolve();
        })
        .catch((error: Error) => {
            // Log error
            console.error('Axios refresh error', error);

            // Logout user
            return store.dispatch('auth/logout').then(() => Promise.reject());
        });
};

createAuthRefreshInterceptor(Axios, retryLogic, {
    statusCodes: [401, 403, 422],
    retryInstance: Axios,
});

// Request interceptor
Axios.interceptors.request.use(async (request) => {
    // Refresh token if needed
    // await store.dispatch('auth/safeRefresh').catch((err: any) => console.warn(err));

    // Always inject latest token
    request.headers!.Authorization = `Bearer ${(store.state.auth as AuthState)?.tokens?.token}`;
    return request;
});

export default Axios;
