export default {
    persistentPreferredActivities: 'Default activity to manage specified intents',
    persistentPreferredActivitiesItem: 'activity | activities',
    persistentPreferredActivitiesActions: 'Intent actions',
    persistentPreferredActivitiesActionsItem: 'action | actions',
    persistentPreferredActivitiesCategories: 'Intent categories',
    persistentPreferredActivitiesCategoriesItem: 'category | categories',
    permittedInputMethods: 'Only permit input methods provided by these apps',
    permittedInputMethodsItem: 'permit | permits',
    permittedAccessibilityServices: 'Whitelisted accessibility services by application',
    permittedAccessibilityServicesItem: 'whitelist | whitelists',
    frpAdminEmails: 'Whitelisted emails that can use their Google account to factory reset devices',
    frpAdminEmailsItem: 'email | emails',
    privateKeyRules: 'Rule set for automatically selecting a private key and certificate to authenticate device to servers',
    privateKeyRulesItem: 'rule | rules',
    privateKeyRulesPackageNames: 'Apps subject to this rule',
    privateKeyRulesPackageNamesItem: 'app | apps',
    label: {
        receiverActivity: 'Receiver App',
        actions: 'Action',
        categories: 'Category',
        permittedInputMethods: 'App',
        permittedAccessibilityServices: 'App',
        title: 'Title',
        description: 'Description',
        packageName: 'App',
        frpAdminEmails: 'Email',
        urlPattern: 'Outgoing URL request to match',
        privateKeyAlias: 'Key alias',
        packageNames: 'App',
        privateKeySelectionEnabled: 'Allow device users to select a private key if none match from rules above',
        allowEmmCompanionVpnExemption: 'Allow the companion app to be exempt from VPN lockdown if the VPN cannot make a connection',
        usageLog: {
            main: 'Usage log',
            enabledLogTypes: 'Enabled log types',
            uploadOnCellularAllowed: 'Allowed log types to upload on cellular data',
        },
    },
    select: {
        logType: {
            networkActivity: 'Network activity',
            security: 'Security',
        },
    },
};
