export default {
    name: 'Name',
    description: 'Description',
    active: 'Active',
    actions: 'Actions',
    reportType: 'Report type',
    requester: 'Requester',
    dateGenerated: 'Date generated',
    token: 'Token',
    created: 'Created',
    url: 'URL',
    type: 'Type',
    status: 'Status',
    policy: 'Policy',
    expiration: 'Expiration',
    enrollmentUrl: 'Enrollment URL',
    added: 'Added',
    title: 'Title',
    role: 'Role',
    email: 'Email',
    domain: 'Domain',
    registered: 'Registered',
    stripeId: 'Stripe ID',
    country: 'Country',
    session: 'Session',
    loggedIn: 'Logged in',
    lastActivity: 'Last activity',
    joined: 'Joined',
    package: 'Package',
    version: 'Version',
    state: 'State',
    commandType: 'Command type',
    executed: 'Executed',
    expiry: 'Expiry',
    variable: 'Variable',
    packageName: 'Package name',
    devices: 'Devices',
    freeDevices: 'Free devices',
    stripeCustomerId: 'Stripe customer ID',
    paymentMethod: 'Payment method',
    invoices: 'Invoices',
    trialEndDate: 'Trial end date',
    activeDevices: 'Active devices',
    plan: 'Plan',
    team: 'Team',
    subscriptionStatus: 'Subscription status',
    readOnlyStatus: 'Read-only status',
    coupon: 'Coupon',
    nonPaymentFlowState: 'Non-payment flow',
    invoicingType: 'Invoicing type',
    nextBillingDate: 'Upcoming invoice',
    expirationDate: 'Expiration date',
    onboardingState: 'Onboarding state',
    userFacingState: 'User facing state',
    pendingDelete: 'Pending delete',
    verificationState: 'Verification state',
    teamOwner: 'Team owner',
    credit: 'Credit',
    notes: 'Notes',
};
