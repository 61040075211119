export enum StripeSubscriptionState {
    /** Subscription exists, is in active or trialing state, and associated with a plan in the DB */
    OK = 'OK',
    /** Subscription exists, but is in a state other than active and trialing */
    CANCELLED = 'CANCELLED',
    /** Subscription exists, but is not associated to any plan on the DB */
    BROKEN = 'BROKEN',
    /** No subscription exists */
    EMPTY = 'EMPTY',
    /** Not applicable because it is managed manually or hasn't finished onboarding */
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}
