export default {
    nonPaymentFlowOptions: {
        inactive: 'Inactive',
        ongoing: 'Ongoing',
        paused: 'Paused',
    },
    subscriptionStateOptions: {
        ok: 'Active',
        broken: 'Broken',
        cancelled: 'Cancelled',
        empty: 'Empty',
    },
    invoicingTypeOptions: {
        manual: 'Manual',
        stripe: 'Automatic (Stripe)',
    },
    hasUnpaidInvoicesOptions: {
        allPaid: 'All paid',
        unpaid: 'Unpaid',
    },
    hasValidPaymentMethodOptions: {
        valid: 'Valid',
        notSet: 'Not set',
    },
    trialEndDateOptions: {
        endsDate: 'Ends {0}',
        noTrial: 'Not active',
        endedDate: 'Ended {0}',
    },
};
