/**
 * Enrollment token personal usage allowance
 *
 * Set between allowed (Allow work & personal profiles), disallowed (Fully managed), or dedicated (Userless e.g. Kiosk)
 */
export enum AllowPersonalUsage {
    ALLOW_PERSONAL_USAGE_UNSPECIFIED = 'ALLOW_PERSONAL_USAGE_UNSPECIFIED',
    PERSONAL_USAGE_ALLOWED = 'PERSONAL_USAGE_ALLOWED',
    PERSONAL_USAGE_DISALLOWED = 'PERSONAL_USAGE_DISALLOWED',
    DEDICATED_DEVICE = `DEDICATED_DEVICE`,
    PERSONAL_USAGE_DISALLOWED_USERLESS = `PERSONAL_USAGE_DISALLOWED_USERLESS`,
}
