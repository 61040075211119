export default {
    title: 'Enrollment Tokens',
    item: 'token | tokens',
    text: 'Enrollment tokens are used to enrol devices into management. Each created token generates both a QR code, and DPC extras for use on the zero-touch portal.',
    zeroTouchHeader: {
        notAvailable: 'Zero-touch management has not been activated by your reseller, please contact them so your team can use it.',
        setup: 'Enrollments can be made via tokens or zero-touch.',
        ownerNotSetup: "Zero-touch management requires setup before you're able to use it, please open the zero-touch action to proceed.",
        nonOwnerNotSetup: 'Zero-touch management is available but needs to be setup by a team owner.',
    },
    addDialog: {
        title: 'Create Enrollment Token',
    },
    qrDialog: {
        title: 'Details for {0} token',
        text: {
            p1: 'This enrollment uses the',
            p2: 'policy',
            expires: 'and expires on',
        },
    },
    deleteDialog: {
        title: 'Delete token',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'token from',
            p3: '?',
        },
    },
    bulkDeleteDialog: {
        title: 'Remove token | Remove tokens',
        text: {
            p1: 'Are you sure you want to remove',
            p2: 'token from | tokens from',
            p3: '?',
        },
    },
    network: 'Network:',
    noData: 'You have not created any tokens',
    unknownPolicy: 'Unknown policy',
    allowPersonalUsage: {
        unspecified: 'Default',
        allowed: 'Allow',
        disallowed: 'Disallow',
        disallowUserLess: 'Disallow userless',
    },
    multiMatchToDelete: 'Delete 1 token | Delete {count} tokens',
    matchToDelete: 'Delete {0}',
    zeroTouchDialog: {
        title: 'Zero-touch configuration',
    },
    zeroTouchSetup: {
        owner: "We're creating a zero-touch account with your current email, and we'll add all current owners as well.",
        nonOwner: 'Please contact a team owner to setup Zero-touch.',
    },
    noExpiration: 'No expiration',
};
