export default {
    pleaseUse: 'Please use a',
    deviceNotReady: 'Android device not ready for remote control yet, please try again in a few minutes.',
    remoteControlScreenSize: 'Remote control not supported on browsers that run under a certain resolution.',
    StateMessage: {
        noSession: "We couldn't connect, please try again",
        wakingDevice: 'Waking device',
        wakeFailed: 'Waking device failed, please try again later',
        establishingConnection: 'Establishing connection',
        sessionEnded: 'Session ended',
        deviceTimedOut: 'Device connection timed out',
        timedOut: 'Server connection timed out',
        newerSocketJoinedCall: 'A newer login session for your user ID joined the session',
    },
    establishedInfo: {
        p1: 'Connection established.',
        p2: 'If the device screen is taking too long to show up, please either trigger a sidebar action or tap / swipe on the device itself.',
    },
    ButtonMessage: {
        noSession: 'Wake device',
        wakingDevice: 'Retry wake',
        wakeFailed: 'Retry wake',
        deviceEndedSession: 'Wake device',
        deviceTimedOut: 'Reconnect',
        timedOut: 'Reconnect',
        newerSocketJoinedCall: 'Reconnect',
    },
    deviceActions: {
        back: 'Back',
        home: 'Home',
        recents: 'Recents',
        lockScreen: 'Lock screen',
        notifications: 'Notifications',
        power: 'Power',
        quickSettings: 'Quick settings',
        screenshot: 'Screenshot',
        toggleSplitScreen: 'Toggle split screen',
    },
};
