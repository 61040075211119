export default {
    title: 'Policies',
    item: 'policy | policies',
    text: {
        p1: 'Use',
        p2: 'to build your policy. You can reuse these components across multiple polices.',
    },
    addDialog: {
        title: 'Add policy',
    },
    duplicateDialog: {
        title: 'Duplicate {0} policy',
    },
    deleteDialog: {
        title: 'Delete policy',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'policy from this team?',
        },
    },
    bulkDeleteDialog: {
        title: 'Remove policy | Remove policies',
        text: {
            p1: 'Are you sure you want to remove',
            p2: 'policy from | policies from',
            p3: '?',
        },
    },
    componentUpdateDialog: {
        title: "{0} policy's components",
        updateText: {
            p1: 'You are updating',
            p2: 'on this policy.',
        },
        components: '1 component | {count} components',
        updateConfirmation: 'Please make sure these are the changes you want on the policy.',
    },
    thisWillSet:
        'This will set the applied policy of 1 device to the default policy. Proceed only if you are sure of this change. | This will set the applied policy of {count} devices to the default policy. . Proceed only if you are sure of this change.',

    searchFields: 'name',
    noData: 'You have not added any policies',
    multiMatchToDelete: 'Delete 1 policy | Delete {count} policies',
    matchToDelete: 'Delete {0}',
    offlineCompliancePolicy: 'This is your offline compliance policy, changing it will cause all policies to redeploy and to send the new config to devices.',
    offlineCompliancePolicyReadOnly: 'This is your offline compliance policy.',
    offlineComplianceDelete: 'Note that this policy is set as the offline compliance policy, deleting it will cause the offline compliance to be cleared.',
    offlineComplianceBulkDelete:
        'Note that the policy named {policyName} has been selected and is set as the offline compliance policy, deleting it will cause the offline compliance to be cleared.',
    deployPendingAlert: 'Policy is in the process of being deployed',
};
