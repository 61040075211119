var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"formulate-input-element p-relative"},[(_vm.noDropdown)?_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates ? _vm.allowedDates : _vm.appliedAllowedDates,"color":"primary","no-title":"","range":_vm.range},model:{value:(_vm.context.model),callback:function ($$v) {_vm.$set(_vm.context, "model", $$v)},expression:"context.model"}}):_c('v-menu',{staticClass:"mb-4",attrs:{"attach":"","close-on-content-click":false,"min-width":"auto","nudge-right":0,"nudge-top":-4,"offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"mb-4"},[_c('v-btn',_vm._g(_vm._b({staticClass:"input-button mr-2",class:{
                        '--isOpen': attrs['aria-expanded'] !== 'false',
                    },attrs:{"aria-label":_vm.$t('label.ariaLabel.pickInitialDate'),"block":"","color":"grey lighten-2","outlined":""}},'v-btn',attrs,false),on),[_c('span',{class:{
                            'select-placeholder text-capitalize fw-regular': !_vm.context.model,
                            'grey--text text--darken-3': _vm.context.model,
                        }},[_vm._v(" "+_vm._s(_vm.context.model.length ? _vm.handleDateDisplay() : 'select x')+" ")]),_c('v-icon',{staticClass:"arrow-icon ml-auto",class:{
                            '--down': attrs['aria-expanded'] === 'false',
                            '--up': attrs['aria-expanded'] !== 'false',
                        },attrs:{"right":"","size":"1.125rem"}},[_vm._v(" mdi-menu-down ")])],1)],1)]}}]),model:{value:(_vm.menuModel),callback:function ($$v) {_vm.menuModel=$$v},expression:"menuModel"}},[_c('v-date-picker',{class:{ noYear: _vm.noYear },attrs:{"allowed-dates":_vm.allowedDates ? _vm.allowedDates : _vm.appliedAllowedDates,"color":"primary","header-date-format":_vm.noYear ? _vm.getMonthName() : undefined,"no-title":"","range":_vm.range},on:{"input":_vm.handleDateInput},model:{value:(_vm.context.model),callback:function ($$v) {_vm.$set(_vm.context, "model", $$v)},expression:"context.model"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }