/**
 * Reason for Remote Control session ending
 * - `DEVICE_LEFT` - The Device left the remote control session gracefully
 * - `DEVICE_TIMED_OUT` - The connection to the device timed out, or device didn't join the session
 * - `SOCKET_TIMED_OUT` - The clients socket timed out, this is sent as a courtesy and may not reach the client
 * - `NEWER_SOCKET_JOINED_CALL` - A new socket joined with the user ID or device ID of an existing socket
 */
export enum RemoteControlEndReason {
    DEVICE_LEFT = 0,
    DEVICE_TIMED_OUT,
    SOCKET_TIMED_OUT,
    NEWER_SOCKET_JOINED_CALL,
}
