import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import BooleanSelect from '@/components/form/select/BooleanSelect.vue';
import NumericInput from '@/components/form/input/NumericInput.vue';
import ReversedCheckbox from '@/components/form/checkbox/ReversedCheckbox.vue';
import FormulateInputAutocomplete from '@/components/form/select/FormulateInputAutocomplete.vue';
import FormulateInputDate from '@/components/form/date/FormulateInputDate.vue';
import ChipInput from '@/components/form/chipInput/ChipInput.vue';

export const VueFormulateOptions = {
    library: {
        booleanSelect: {
            classification: 'select',
            component: 'BooleanSelect',
            slotProps: {
                component: ['allowUndefined'],
            },
        },
        number: {
            classification: 'text',
            component: NumericInput,
        },
        reversedCheckbox: {
            classification: 'box',
            component: ReversedCheckbox,
        },
        chipInput: {
            component: ChipInput,
            slotProps: {
                component: ['disabled', 'placeholder'],
            },
        },
        autocomplete: {
            component: FormulateInputAutocomplete,
            slotProps: {
                component: ['borderless', 'disabled', 'fetch', 'placeholder'],
            },
        },
        date: {
            component: FormulateInputDate,
            slotProps: {
                component: ['range', 'noDropdown', 'noYear', 'allowedDates'],
            },
        },
    },
    mimes: {
        csv: 'text/csv',
        csv_excel: 'application/vnd.ms-excel',
        text: 'text/plain',
    },
};

Vue.component('NumericInput', NumericInput);
Vue.component('BooleanSelect', BooleanSelect);
Vue.component('ReversedCheckbox', ReversedCheckbox);
Vue.use(VueFormulate, VueFormulateOptions);
