export default {
    title: 'Profile settings',
    error_email: 'This email is in use, please enter a different email.',
    error: 'There was an error updating your profile, please try again.',
    verify_email: 'An email has been sent to verify your new email.',
    genderOptions: {
        male: 'Male',
        female: 'Female',
        nonbinary: 'Non-binary',
        unknown: 'Prefer not to say',
    },
    pendingChangeHelper: 'Pending verification to change email to {0}',
    preferredTeamHelp: `You will be moved to the selected preferred team when you login. If there's no team set, you will be moved to the latest team you joined by default.`,
};
