
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { VueFormulateContext } from '@/util/vueformulate-context';

import { OptionItem } from '@/util/option-item.type';
import { PaginatedData } from '@/util/page-data.type';
import { SearchFn } from '@/util/autocomplete.type';
import { traverseObject } from '@/util/traverse-object';
import { FilterFetch } from '@/util/table-types.type';

enum State {
    DEFAULT = 'DEFAULT',
    LOADING = 'LOADING',
}

@Component({})
export default class FormulateInputAutocomplete extends Vue {
    @Prop({ required: true }) context!: VueFormulateContext;
    @Prop({ default: false, type: Boolean }) borderless!: boolean;
    @Prop({ default: false, type: Boolean }) disabled!: boolean;
    @Prop() fetch!: FilterFetch;
    @Prop({
        default() {
            return (this as Vue).$t('button.select');
        },
    })
    placeholder!: string;

    // State
    State = State;
    currentState: State = State.DEFAULT;

    search = '';
    items: OptionItem[] = [];
    initialItems: OptionItem[] = [];
    page = 1;
    total = 0;
    limit = 5;

    virtualPages: OptionItem[][] = [];

    // Keep track of fetch object changes
    fetchUpdated = true;

    // To display items from fetch via label
    storedItems: Record<OptionItem['value'], OptionItem['text']> = {};

    get lastPage() {
        return Math.ceil(this.total / this.limit);
    }

    get hasFetch() {
        return !!this.fetch;
    }

    get handleItems() {
        // Field if of fetch type
        if (this.fetch) {
            // Ignore virtual pages if there's a search
            if (this.search) return this.items;

            // Return from items on virtual page if any
            // Otherwise give normal items
            return this.virtualPages[this.page - 1] ?? this.items;
        } else {
            // Field if of options type
            return this.context.options;
        }
    }

    get isMultiple() {
        return !!this.context.attributes?.multiple;
    }

    get showCustomPlaceholder() {
        // Don't show placeholder if there's no fetch
        // Because its handled by default non-custom placeholder
        if (!this.fetch) {
            return false;
        }

        // Show placeholder if multiselect and no search
        if (this.isMultiple) {
            return !this.search;
        } else {
            // In case of single-select
            // show placeholder if value is not selected and search is empty
            return this.isContextModelEmpty && !this.search;
        }
    }

    // To check whether both labelField and valueField have a defined value
    get fetchHasLabelAndValueFields() {
        return !!this.fetch?.valueField && !!this.fetch?.labelField;
    }

    get placeholderValue() {
        return this.fetch ? undefined : this.placeholder;
    }

    // Display selected items when there's fetch and it is single select
    get displaySingleSelectValue() {
        if (this.isMultiple) {
            return;
        }
        return this.fetchHasLabelAndValueFields && this.storedItems ? this.storedItems[this.context.model] : this.context.model;
    }

    get isContextModelEmpty() {
        return this.context.model.length === 0;
    }

    get renderErrors() {
        return (
            // Has at least one error to display
            this.context.validationErrors?.length &&
            // State is not loading
            this.currentState !== State.LOADING
        );
    }

    async created() {
        // Handle field of type fetch
        if (this.fetch) {
            this.currentState = State.LOADING;

            // Get model from context
            const { model } = this.context;
            let initialModel;

            // Model already has values selected
            if (!this.fetch.disableVirtualPages && Array.isArray(model) && model.length) {
                // Store values in `initialModel`
                initialModel = [...model];

                // Set an index to use when generating virtual pages object
                let index = 0;

                // Generate virtual pages object
                [...this.context.model].forEach((value: any) => {
                    // Set object format { value, text }
                    const objValue = { value, text: value };

                    // Add value to virtual page
                    // $set needs to be used for reactivity
                    this.$set(this.virtualPages, index, this.virtualPages[index] ? [...this.virtualPages[index], objValue] : [objValue]);

                    // If limit is reached, go to next virtual page
                    if (this.virtualPages[index].length === this.limit) {
                        index += 1;
                    }
                });
            }

            // Check if there's virtual pages
            if (this.virtualPages.length) {
                // Get last page of pages object
                const lastVirtualPage = this.virtualPages[this.virtualPages.length - 1];

                // Last page doesn't have enough items
                if (lastVirtualPage && lastVirtualPage.length < this.limit) {
                    // Fetch items needed to to fill page + next page
                    // Pass in selected values so they are excluded
                    // Set limit to be itemsNeeded + limit
                    // Send true so `fetchData` returns the data instead of setting it
                    const fetchedItems = await this.fetchData(initialModel, this.limit - lastVirtualPage.length + this.limit, true);

                    // Check if we received any items
                    if (fetchedItems?.length) {
                        // Fill what's left of last page
                        let lastVirtualPageNumber = this.virtualPages.length - 1;

                        fetchedItems.forEach((item) => {
                            if (this.virtualPages[lastVirtualPageNumber].length < this.limit) {
                                this.virtualPages[lastVirtualPageNumber].push(item);
                            } else {
                                // Move to next page
                                lastVirtualPageNumber += 1;
                                this.virtualPages.push([item]);
                            }
                        });
                    }
                } else {
                    // Finish loading
                    this.currentState = State.DEFAULT;
                }

                // Set last virtual page as items
                // This serves as a placeholder and avoids a UI glitch
                // Due to `items` being empty for a split second before fetch
                this.items = this.virtualPages[this.virtualPages.length - 1];
            } else {
                // Fetch to get initial items
                this.getInitialItems();
            }

            // Might need to fetch initial items if the label doesn't match the value
            if (this.fetchHasLabelAndValueFields && model.length) {
                // Set as an array when model is single select
                const modelAsArray = this.isMultiple ? model : [model];

                await this.fetchData(undefined, undefined, undefined, modelAsArray);
            }

            await this.$forceUpdate();

            // Create custom placeholder
            // This is needed for fetch-type inputs

            // Find input element
            const targetElement = this.$el.querySelector('.v-select__selections input');

            // Add placeholder element before that node
            if (targetElement) {
                // Create placeholder
                const placeholderSpan = document.createElement('span');
                placeholderSpan.textContent = this.placeholder;
                placeholderSpan.classList.add('custom-placeholder');
                placeholderSpan.classList.add('--show');

                // Attach before input
                targetElement.parentNode?.insertBefore(placeholderSpan, targetElement);
            }
        }
    }

    handleBlur() {
        // Empty search when is it multi-select and a value is selected
        if (this.isMultiple && !this.isContextModelEmpty) {
            this.search = '';
        }

        // Empty search on blur since visually this is what happens
        if (this.isContextModelEmpty) {
            this.search = '';
        }
    }

    /**
     * To fetch items data
     * @param {string[]} exclude - items to be excluded from fetch
     * @param {number} amount - amount of items we want to fetch, it will be set as the `limit` of the endpoint
     * @param {boolean} returnData - whether to return the items or set them on our `items` variables as normally
     * @param {string[]} include - relies on `valueField` and `labelField`, both need to be set for it to work
     */
    async fetchData(exclude?: string[], amount?: number, returnData?: boolean, include?: string[]): Promise<Array<any> | undefined> {
        this.currentState = State.LOADING;

        try {
            /* When there are both label and value fields set, use label */
            let [field] = this.fetchHasLabelAndValueFields ? [this.fetch.labelField as string] : this.fetch.select;

            if (!field || (!this.fetch.url && !this.fetch.function)) return;

            /** A custom function that can be used to fetch results instead of hitting the backend */
            const searchFn = this.fetch.function ? (this.fetch.function as unknown as SearchFn) : null;
            /** Items to be excluded from the result by the search function */
            const searchFnExcludes: string[] = [];

            if (this.fetch.association) field = this.fetch.association;

            let query = [];

            if (this.search) {
                query.push(`${field}:like:%${this.search}%`);
            } else {
                // Only exclude items if there's no search
                if (exclude) {
                    query.push(`${field}:notIn:${JSON.stringify(exclude)}`);
                    searchFnExcludes.push(...exclude);
                } else if (this.virtualPages.length) {
                    const flat = this.virtualPages.flat();

                    query.push(
                        `${field}:notIn:${JSON.stringify(
                            // Only set the item value, not whole object
                            flat.map((item) => item.value),
                        )}`,
                    );
                    searchFnExcludes.push(...flat.map((item) => item.value));
                }
            }

            if (this.fetch.query) {
                query = [...query, ...this.fetch.query];
            }

            if (include && this.fetchHasLabelAndValueFields) {
                query.push(`${this.fetch.valueField}:in:${JSON.stringify(include)}`);
            }

            let endpointPage = this.page;
            let virtualTotal = 0;

            // Handle virtual pages, if any
            if (this.virtualPages.length) {
                // Update virtual total so its added to total amount later
                virtualTotal = this.virtualPages.flat().length;

                if (this.page <= this.virtualPages.length) {
                    endpointPage = 1;
                } else {
                    // Update page to use in endpoint acording to virtual page
                    endpointPage = this.page - this.virtualPages.length;
                }
            }

            let data: PaginatedData<Record<string, any>>;

            // If custom search function is passed, we execute that
            if (searchFn) {
                data = await searchFn(this.search, endpointPage, amount ?? this.limit, searchFnExcludes);
            } else {
                // otherwise we hit the backend
                const result = await this.$axios.get<PaginatedData<Record<string, any>>>(this.fetch.url, {
                    params: {
                        select: this.fetch.select,
                        page: endpointPage,
                        limit: include ? 50 : amount ?? this.limit,
                        q: query.length ? query : undefined,
                        mode: this.search ? 'and' : undefined,
                        // If `includes` is set then ensure it's fetching something,
                        // otherwise, request `null` for no associations
                        includes: (this.fetch?.includes?.length ?? 0) > 0 ? this.fetch.includes : 'null',
                    },
                });
                data = result.data;
            }

            let items: any[];

            // Check if the `field` exists as direct property inside the object,
            // because sometimes `field`s can contain . (dot) in them
            // We attach the whole item as `item` since its used for custom formatting
            if (field.includes('.') && typeof data.data?.[0]?.[field] === 'undefined') {
                // No, it must be nested object
                items = data.data.map((item) => ({
                    // as backend replacing . with '__'(double underscore), so updating the same in UI
                    value: traverseObject(item, field.replaceAll('.', '__')),
                    item,
                }));
            } else {
                // Yes, it is a direct property
                items = data.data.map((item) => ({ value: item[field], item }));
            }

            // Set total if not looking for expecific values
            if (!include) {
                this.total = data.total + virtualTotal;
            }

            let hasEmptyOption = false;

            type ValueItem = { value: string | null; item: any };

            // Set format
            items = items
                .filter(({ value }: ValueItem) => {
                    if (value === null || value === '') {
                        if (!hasEmptyOption) {
                            hasEmptyOption = true;
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                })
                .map(({ value, item }: ValueItem) => {
                    let finalItem: OptionItem;

                    // Handle empy-like values
                    if (value === null || value === '') {
                        finalItem = {
                            value: '',
                            text: this.$t('misc.empty'),
                        };
                    } else {
                        finalItem = {
                            value: this.fetchHasLabelAndValueFields ? item[this.fetch.valueField as string] : value,
                            text: this.fetchHasLabelAndValueFields ? item[this.fetch.labelField as string] : value,
                        };
                    }

                    // Store on map if not there already and fetch has both label and value fields
                    if (this.fetchHasLabelAndValueFields && !(finalItem.value in this.storedItems)) {
                        this.$set(this.storedItems, finalItem.value, finalItem.text);
                    }

                    return finalItem;
                });

            // Include fetch, ignore any `items` setting
            // on includes all we need is storing the items on `storedItems`
            // which is done above these lines
            if (include) {
                this.currentState = State.DEFAULT;
                return;
            }

            // Set new items on data if they shouldn't be returned
            if (!returnData) {
                this.items = items;
                await this.$nextTick();
            }

            // End loading
            this.currentState = State.DEFAULT;

            // Return items if applicable
            if (returnData) return items;
        } catch (e) {
            console.warn(e);

            // Unset items
            this.initialItems = [];
            this.items = [];
            this.currentState = State.DEFAULT;

            return;
        }
    }

    async getInitialItems() {
        await this.fetchData();

        // Set as items
        this.initialItems = this.items;
    }

    nextPage() {
        this.page += 1;
    }

    previousPage() {
        this.page -= 1;
    }

    async handleDeleteKey() {
        // Handle for single-select
        if (!this.isMultiple) {
            // Remove item
            this.context.model = '';
            return;
        }

        // If its not a fetch type of select ignore custom remove
        // Make sure the input is empty without a search
        if (!this.hasFetch || this.search) return;

        // No items to remove
        if (this.isContextModelEmpty) return;

        const itemValues = this.items.map((item) => item.value);

        // Check if any item is selected on the current page
        const hasITemOnPage = this.context.model.some((value: string) => itemValues.indexOf(value) >= 0);

        // Vuetify removes the last item automatically
        // No need to do it ourselves
        if (hasITemOnPage) return;

        // Otherwise we need to do it ourselves
        this.context.model.pop();
    }

    focusAutocomplete() {
        ((this.$refs.vAutocomplete as Vue)?.$el as HTMLDivElement)?.querySelector('input')?.click();
    }

    // Display selected items in multiselect
    // Displays from storedItems when there is a fetch, or displays the item
    displayMultiSelectValue(value: string) {
        if (value === '') {
            return this.$t('misc.empty');
        }
        return this.fetchHasLabelAndValueFields && this.storedItems[value] ? this.storedItems[value] : value;
    }

    @Watch('fetch.url')
    @Watch('fetch.select')
    async onFetchChange() {
        this.fetchUpdated = true;

        // Clear model since new values will come from change
        // Only when the selection is multiple, not needed for standard selects as these always have a value
        if (this.isMultiple) {
            this.context.model = [];
        }
        this.page = 1;
        // When fetch changes, the values stored on virtual pages
        // Are not applicable because they belong to the older fetch
        this.virtualPages = [];

        if (this.fetch) {
            // Fetch new items
            this.getInitialItems();
        }
    }

    @Watch('page')
    async onPageChange() {
        // Only fetch if the field if of type fetch
        // And if there's no virtual page in this current page
        if (this.fetch && !this.virtualPages[this.page - 1]) {
            this.fetchData();
        }
    }

    @Watch('search')
    async onSearchChange(current: string | null, old: string | null) {
        // Single-select case with fetch
        if (this.fetch && !this.isMultiple) {
            // If a search initializes while an item is selected, clear selection
            if (this.search && !this.isContextModelEmpty) {
                this.context.model = '';

                // Avoid search getting out of sync after model update
                await this.$nextTick();
                this.search = current ?? '';
                (this.$refs.vAutocomplete as Vue).lazySearch = this.search;
            }
        }

        // Don't consider a search change when going from '' to null
        // This is a side-effect of selecting sometimes
        if (current === null && old === '') {
            return;
        }

        // Open menu if space is the first thing typed on the input
        if (current === ' ') {
            this.search = '';
            (this.$refs.vAutocomplete as Vue).lazySearch = '';
        }

        // If current is empty ('') or null
        if (!this.fetch || !current) {
            // Search was emptied, set back initial items
            this.items = this.initialItems;

            if (!this.fetch || current === null) {
                return;
            }
        }

        // Set 1st page if not on it already
        if (this.page !== 1) {
            this.page = 1;
        }

        // Fetch items
        await this.fetchData();

        // Apply focus back into input in case it was lost
        this.focusAutocomplete();
    }

    @Watch('context.model')
    onModelChange(current: any, old: any) {
        // Single select has a new selection, clear search
        if (!this.isMultiple && !this.isContextModelEmpty) {
            this.search = '';
        }

        // Check if change was triggered by a fetch change
        if (this.fetchUpdated) {
            // Avoid mode update and reset fetchUpdated
            this.fetchUpdated = false;
        } else {
            if (current.length === 0 && old.length >= 2 && !this.search && this.isMultiple) {
                // Vuetify has cleared the model
                // Make sure to only remove last item instead
                old.pop();

                this.context.model = old;
            }
        }
    }

    @Watch('showCustomPlaceholder')
    onShowCustomPlaceholderChange() {
        // Find custom placeholder element
        const targetElement = this.$el.querySelector('.custom-placeholder');
        if (!targetElement) return;

        // Exclude handling non-fetch inputs
        if (!this.fetch) {
            // Hide if present
            targetElement.classList.remove('--show');
            return;
        }

        // Apply style to display placeholder
        if (this.showCustomPlaceholder) {
            targetElement.classList.add('--show');
        }
        // Apply style to hide placeholder
        else {
            targetElement.classList.remove('--show');
        }
    }
}
