/**
 * Component Types Enum
 */

export enum ComponentTypes {
    Security = 'security',
    Network = 'network',
    General = 'general',
    Advanced = 'advanced',
    Kiosk = 'kiosk',
    Applications = 'applications',
}
