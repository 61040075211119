export default {
    title: 'Register {0}',
    text: {
        p1: "This team doesn't exist. If you want to register",
        p2: 'for yourself you can',
        hasEmail: {
            p1: 'go to your',
            p2: 'and create it.',
        },
        notHasEmail: {
            p1: 'to your account or',
            p2: 'to create one.',
        },
    },
};
