// General
import button from './button.en';
import label from './label.en';
import link from './link.en';
import misc from './misc.en';
import state from './state.en';
import validation from './validation.en';
import header from './header.en';
import action from './action.en';
import placeholder from './placeholder.en';
import options from './options.en';
import support from './support.en';
// Pages
import page from './page/page.en';
// Components
import component from './component/component.en';
// Layouts
import layout from './layout/layout.en';
// Utils
import util from './util/util.en';
// Data
import data from './data/data.en';

export default {
    button,
    label,
    link,
    misc,
    placeholder,
    state,
    validation,
    header,
    action,
    options,
    page,
    component,
    layout,
    util,
    support,
    data,
};
