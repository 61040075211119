export default {
    title: 'Audit Log {0}',
    name: 'Name',
    date: 'Date',
    action: 'Action',
    noData: 'No audit log available.',
    actionType: {
        insert: 'Added',
        update: 'Changed',
        delete: 'Removed',
    },
};
