export default {
    title: 'Roles',
    item: 'role | roles',
    text: "Roles can be customized with various permissions. If a role is in use, it can't be deleted.",
    showAddDialog: {
        title: 'Add new role',
        error_duplicate: 'A role with the same name already exists',
    },
    showEditDialog: {
        title: 'Edit {0} role',
    },
    showDeleteDialog: {
        title: 'Delete role',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'Role from',
            p3: '?',
        },
    },
    showBulkDeleteDialog: {
        title: 'Delete role | Delete roles',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'role from | roles from',
            p3: '?',
        },
    },
    showViewDialog: {
        title: 'View {0} role',
    },
    searchFields: 'name',
    noData: 'You have not added any roles',
    matchToDelete: 'Delete {0}',
    multiMatchToDelete: 'Delete 1 role | Delete {count} roles',
};
