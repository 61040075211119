export enum WebhookEvents {
    ACCESS_TOKEN_CREATE = 0,
    ACCESS_TOKEN_UPDATE,
    ACCESS_TOKEN_DELETE,
    DEVICE_CREATE,
    DEVICE_UPDATE,
    DEVICE_DELETE,
    ENROLLMENT_CREATE,
    ENROLLMENT_UPDATE,
    ENROLLMENT_DELETE,
    AMAPI_ENTERPRISE_CREATE,
    AMAPI_ENTERPRISE_DELETE,
    MEMBERSHIP_CREATE,
    MEMBERSHIP_UPDATE,
    MEMBERSHIP_DELETE,
    POLICY_CREATE,
    POLICY_UPDATE,
    POLICY_DELETE,
    POLICY_COMPONENT_CREATE,
    POLICY_COMPONENT_UPDATE,
    POLICY_COMPONENT_DELETE,
    RESELLER_UPDATE,
    ROLE_CREATE,
    ROLE_UPDATE,
    ROLE_DELETE,
    TEAM_UPDATE,
}
