const readOnly =
    'Team members are not be able to make changes to any aspect of the team in this state, all enrolment and access tokens have been removed. Your devices are unaffected.';

export default {
    pendingDelete: {
        byMember: {
            p1: `This team is in the process of deletion and it has been set to read-only mode, it will be deleted on {0}. ${readOnly}`,
            p2: 'Team owners have {0} days to cancel this procedure to recover the team.',
            forOwners: {
                p1: "You can visit your team's",
                p2: 'to stop the deletion process.',
            },
        },
        byAdmin: {
            p1: `This team has been set to be deleted by an admin and read-only mode has been activated, team will be deleted on {0}. ${readOnly}`,
        },
        invoice:
            'An invoice for the current billing period until the day of the deletion request has been sent. This will need to be paid before your team can be fully deleted.',
    },
    manual: {
        p1: `This team has been set to read-only mode. ${readOnly}`,
    },
    contact: {
        p1: 'Feel free to contact us via our',
        p2: 'to learn more about this.',
    },
};
