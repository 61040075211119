export default {
    title: 'File manager',
    item: 'file | files',
    text: 'File manager are used to upload files to a team storage. The benefit of common files is the ability to reuse them across several libraries, allowing content updates with minimum effort.',
    noData: 'You have no files',
    type: {
        image: 'Image',
        video: 'Video',
        file: 'File',
    },
    deleteDialog: {
        title: 'Delete {0}',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'file from this team?',
        },
        conformation: 'Delete {0}',
    },
    bulkDeleteDialog: {
        title: 'Delete file | Delete files',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'file from | files from',
            p3: '? If used in a library, the file will be removed from the same.',
        },
        conformation: 'Delete 1 file | Delete {count} files',
    },
};
