export default {
    updateSchedule: 'System update schedule',
    freezePeriods: 'Freeze periods',
    freezePeriodsItem: 'period | periods',
    freezePeriodsInfo: 'Freeze periods can be up to 90 days at a time, with a 60 day cool-off period',
    freezePeriodsInfoStart: 'A valid end date will be selected if current one is not in range',
    invalidPeriodDistance: 'This period has a conflict with another one, freeze periods must be at least 60 days apart.',
    lockScreenMessage: 'Lock screen message',
    freezePeriodsError: 'Freeze periods should be up to 90 days long, and have a 60 day cool-off between them.',
    systemUpdateHelp: 'To set freeze periods you need to set the system update to a value that is not default.',
    select: {
        stayOnPluggedModes: {
            ac: 'Outlet',
            usb: 'USB charging',
            wireless: 'Wireless charging',
        },
        systemUpdateType: {
            unspecified: 'Default',
            automatic: 'Automatic',
            windowed: 'On selected schedule',
            postpone: 'Postpone',
        },
        autoDateAndTimeZone: {
            unspecified: 'Default',
            userChoice: 'Manual',
            enforced: 'Enforced',
        },
        locationUploadInterval: {
            never: 'Never',
            every_hour: 'Every hour',
            every_4_hours: 'Every 4 hours',
            every_8_hours: 'Every 8 hours',
            every_12_hours: 'Every 12 hours',
            every_day: 'Every day',
        },
        heartbeatInterval: {
            never: 'Never',
            every_hour: 'Every hour',
            every_4_hours: 'Every 4 hours',
            every_8_hours: 'Every 8 hours',
            every_12_hours: 'Every 12 hours',
            every_day: 'Every day',
        },
        microphoneAccess: {
            unspecified: 'Default',
            user_choice: 'User choice',
            disabled: 'Disabled',
            enforced: 'Enforced',
        },
    },
    label: {
        stayOnPluggedModes: 'The device will remain always on in these charging modes',
        adjustVolumeDisabled: 'Enable changing device volume',
        microphoneAccess: 'Microphone access',
        deviceOwnerLockScreenInfo: 'Message displayed on device lock screen',
        funDisabled: 'Enable the easter eggs in settings',
        autoTimeRequired: 'Require automatic setting of device time',
        skipFirstUseHintsEnabled: 'Skip tutorials for system apps',
        systemUpdate: 'System updates',
        autoDateAndTimeZone: 'Automatic time, date and time zone',
        locationUploadInterval: {
            label: 'Location tracking',
            help: 'Interval at which the device will report its location',
        },
        heartbeatInterval: {
            label: 'Device heartbeat',
            help: 'Interval in which the device will attempt to check-in',
        },
    },
    locationFeature:
        "Want to keep track of your devices? Our location request feature allows you to get a device's location periodically, which you can later see on the device details.",
    displaySettings: {
        title: 'Display Settings',
        screenBrightness: {
            title: 'Screen Brightness',
            unspecified: 'Default',
            userChoice: 'Allow user to set',
            automatic: 'Automatic',
            fixed: 'Set fixed',
            fixedTitle: 'Brightness ({brightness}%)',
        },
        screenTimeout: {
            title: 'Screen Timeout',
            unspecified: 'Default',
            userChoice: 'Allow user to set',
            enforced: 'Set fixed',
            enforcedTitle: 'Duration',
            enforcedHelp: 'in seconds',
        },
    },
};
