export default {
    shortSupportMessage: {
        title: 'Short message for functionalities disabled by admin',
        subtitle: 'If text is over 200 characters, the message may get truncated',
    },
    longSupportMessage: 'Long message displayed on device administrator settings',
    passwordPolicies: 'Password requirement policies',
    passwordPoliciesItem: 'policy | policies',
    accountTypesWithManagementDisabled: "Account types that can't be managed by the user",
    learnMoreAbout: 'Learn more about',
    addPolicyForProfiles: 'Add policy for profiles',
    addPolicyForDevices: 'Add policy for devices',
    accountTypesWithManagementDisabledItem: 'type | types',
    passwordExpirationTimeoutLabel: '{0} in seconds',
    enforcementPolicies: "Actions if the device isn't compliant with policy",
    enforcementPoliciesItem: 'action | actions',
    keyguardDisabledFeaturesFields: {
        label: 'Features & actions on lock screen',
        camera: 'Enable camera',
        notifications: 'Show notifications',
        disableFingerPrint: 'Enable fingerprint unlock',
        face: 'Enable face unlock',
        iris: 'Enable iris unlock',
        trustAgents: 'Allow trust agents on lock screen',
        disableRemoteInput: 'Enable remote input (e.g. notification text input)',
        shortcuts: 'Disable all shortcuts on Android 14 and above.',
    },
    label: {
        encryptionPolicy: 'Set device encryption policy',
        passwordScope: 'Policy scope',
        unifiedLockSettings: 'Unified work and personal lock',
        requirePasswordUnlock: 'Time before password re-entry is required',
        passwordExpirationTimeout: 'Password expiration timeout',
        passwordQuality: 'Password quality',
        passwordMinimumLength: 'Minimum length',
        passwordMinimumLetters: 'Minimum letters',
        passwordMinimumLowerCase: 'Minimum lowercase letters',
        passwordMinimumUpperCase: 'Minimum uppercase letters',
        passwordMinimumNonLetter: 'Minimum non-letters',
        passwordMinimumNumeric: 'Minimum numbers',
        passwordMinimumSymbols: 'Minimum special characters',
        passwordHistoryLength: 'Password changes before reuse is allowed',
        maximumFailedPasswordsForWipe: 'Password failures before device is wiped',
        settingName: 'Policy',
        blockAfterDays: 'Block access after (days)',
        wipeAfterDays: 'Wipe data after (days)',
        preserveFrp: 'Maintain factory reset protection after wipe',
        permission: 'Permission',
        policy: 'Time before password re-entry is required',
        accountTypesWithManagementDisabled: 'Acount type to disable',
        maximumTimeToLock: 'Maximum time allowed before device sleeps due to no activity',
        minimumApiLevel: 'Minimum Android API level requirement for installed apps',
        screenCaptureDisabled: 'Enable screenshots on device',
        cameraAccess: 'Camera access',
        addUserDisabled: 'Enable adding new users and profiles on device',
        factoryResetDisabled: 'Enable factory reset from device settings',
        installAppsDisabled: 'Enable installing apps on device',
        uninstallAppsDisabled: 'Enable uninstalling apps on device',
        modifyAccountsDisabled: 'Enable adding and removing accounts on device',
        keyguardDisabled: 'Enable keyguard on device (Lock screen)',
        credentialsConfigDisabled: 'Enable user credentials configuration on device',
        removeUserDisabled: 'Enable removing others users on device',
        createWindowsDisabled: 'Enable creating windows besides app windows',
        setUserIconDisabled: 'Enable changing the device user icon',
        setWallpaperDisabled: 'Enable changing the device wallpaper',
        shortSupportMessage: 'Default message',
        longSupportMessage: 'Message displayed to the user in the device admins settings screen',
        mountPhysicalMediaDisabled: 'Enable mount of physical external media on device',
        personalUsagePolicies: 'Personal usage policies',
        personalUsagePoliciesCameraDisabled: 'Enable camera',
        personalUsagePoliciesScreenCaptureDisabled: 'Enable screen capture',
        personalUsagePoliciesMaxDaysWithWorkOff: 'Maximum days work profile can stay off',
        personalUsagePoliciesPersonalPlayStoreMode: 'Control how apps in the personal profile are handled',
        untrustedAppsPolicy: 'Security settings for apps from outside Google Play store',
        googlePlayProtectVerifyApps: 'Handling of Google Play Protect verification',
        developerSettings: 'Control access to developer settings & safe boot',
        commonCriteriaMode: 'Control of common criteria mode',
        personalAppsThatCanReadWorkNotifications: {
            main: 'Personal apps that can read work profile notifications',
            help: 'In the form of comma-space separated package names',
        },
        printingPolicy: 'Printing policy',
        crossProfilePolicies: {
            main: 'Cross-profile policies',
            showWorkContactsInPersonalProfile: 'Share contacts from work to personal profile (Show on contact searches, incoming calls, etc.)',
            exemptionsToShowWorkContactsInPersonalProfile: {
                main: 'List of packages that are excluded from contact sharing from work to personal profile',
                help: {
                    allowed: 'Since contact sharing is allowed, packages in this list will be blocked',
                    disallowed: 'Since contact sharing is disallowed, packages in this list will be allowed',
                    disallowedExceptSystem: 'Since contact sharing is disallowed for any non-system app, packages in this list will be allowed',
                },
            },
            crossProfileCopyPaste: 'Share copied text across profiles',
            crossProfileDataSharing: 'Share app data across profiles',
            workProfileWidgetsDefault: 'Handling of work profile widgets if not set for a specific application',
        },
        deviceConnectivityManagement: {
            main: 'Connectivity management',
            usbDataAccess: 'USB data access',
            configureWifi: 'Wi-Fi configuration',
            wifiDirectSettings: 'Wi-Fi direct configuration',
            tetheringSettings: 'Tethering settings',
            wifiSsidPolicy: 'Wi-Fi ssid restriction policy',
            wifiSsidPolicyHelpText: 'supports from 13+',
            wifiSsidPolicyAllowSSID: 'SSIDs to be allowed',
            wifiSsidPolicyBlockSSID: 'SSIDs to be blocked',
        },
        deviceRadioState: {
            main: 'Radio settings',
            wifiState: 'Control Wi-Fi states',
            airplaneModeState: 'Control airplane mode state',
            ultraWidebandState: 'Control ultra wideband state',
            cellularTwoGState: 'Control cellular 2G setting usability',
            minimumWifiSecurityLevel: {
                label: 'Minimum required security level of Wi-Fi networks',
                help: 'Requires Android 13 and above, only supported on fully managed devices and work profiles on company-owned devices',
            },
        },
        mtePolicy: 'Memory Tagging Extension (MTE) policy',
    },
    select: {
        encryptionPolicy: {
            unspecified: 'Default',
            enabledWithoutPassword: "Encryption doesn't require password on boot",
            enabledWithPassword: 'Encryption requires password on boot',
        },
        passwordScope: {
            unspecified: 'Devices and profiles',
            device: 'Devices',
            profile: 'Profiles',
        },
        unifiedLockSettings: {
            unspecified: 'Default',
            requireSeparate: 'Require separate work and personal lock',
            allowUnified: 'Allow unified work and personal lock',
        },
        requirePasswordUnlock: {
            unspecified: 'Default',
            defaultDeviceTimeout: 'Device default',
            requireEveryDay: '24 hours',
        },
        passwordQuality: {
            unspecified: 'No requirements',
            something: 'Required, with no restrictions',
            biometricWeak: 'Biometric',
            numeric: 'Numeric',
            numericComplex: 'Numeric without repeats or sequences (No 22 or 123)',
            alphabetic: 'Alphabetic',
            alphanumeric: 'Alphanumeric',
            complex: 'Custom',
        },
        untrustedAppsPolicy: {
            unspecified: 'Default',
            disallowInstall: "Don't allow installation",
            allowInstallInPersonalProfile: 'Allow in personal space of work profile devices',
            allowInstallDeviceWide: 'Always allow',
        },
        googlePlayProtectVerifyApps: {
            unspecified: 'Default',
            enforced: 'Enforce',
            userChoice: 'User choice',
        },
        developerSettings: {
            unspecified: 'Default',
            disabled: 'Disabled',
            allowed: 'Enabled',
        },
        commonCriteriaMode: {
            unspecified: 'Default',
            enabled: 'Enabled',
            disabled: 'Disabled',
        },
        cameraAccess: {
            unspecified: 'Default',
            user_choice: 'User choice',
            disabled: 'Disabled',
            enforced: 'Enforced',
        },
        crossProfilePolicies: {
            showWorkContactsInPersonalProfile: {
                unspecified: 'Default',
                allowed: 'Allowed',
                disallowed: 'Disallowed',
                disallowedExceptSystem: 'Disallowed except for system apps (Dialer, Messages, Contant apps)',
            },
            crossProfileCopyPaste: {
                unspecified: 'Default',
                allowed: 'Allowed',
                disallowed: 'Disallowed',
            },
            crossProfileDataSharing: {
                unspecified: 'Default',
                allowed: 'Allowed',
                disallowedFromWorkToPersonal: 'Disallowed from work to personal profile',
                disallowed: 'Disallowed always',
            },
            workProfileWidgetsDefault: {
                unspecified: 'Default',
                allowed: 'Allowed',
                disallowed: 'Disallowed',
            },
        },
        deviceConnectivityManagement: {
            usbDataAccess: {
                unspecified: 'Default',
                allow: 'Allowed',
                disallowFile: 'Disallow file transfer but allow other connections (mouse, keyboard, etc.)',
                disallow: 'Disallow all',
            },
            configureWifi: {
                unspecified: 'Default',
                allow: 'Allow',
                disallowAdd: 'Disallow new configurations but allow switch between already configured networks',
                disallow: 'Disallow',
            },
            wifiDirectSettings: {
                unspecified: 'Default',
                allow: 'Allow',
                disallow: 'Disallow',
            },
            tetheringSettings: {
                unspecified: 'Default',
                allow: 'Allow',
                disallowWifi: 'Disallows Wi-Fi tethering',
                disallow: 'Disallow all',
            },
            wifiSsidPolicy: {
                unspecified: 'Default',
                deny: 'Deny',
                allow: 'Allow',
            },
        },
        deviceRadioState: {
            wifiState: {
                unspecified: 'Default',
                userChoice: 'User is allowed to enable/disable Wi-Fi',
                enabled: 'Wi-Fi enforced and not allowed to be turned off',
                disabled: 'Wi-Fi is off and not allowed to be turned on',
            },
            airplaneModeState: {
                unspecified: 'Default',
                userChoice: 'User is allowed to toggle airplane mode',
                disabled: 'Airplane mode is disabled',
            },
            ultraWidebandState: {
                unspecified: 'Default',
                userChoice: 'User is allowed to toggle ultra wideband',
                disabled: 'Ultra wideband is disabled',
            },
            cellularTwoGState: {
                unspecified: 'Default',
                userChoice: 'User is allowed to toggle cellular 2G',
                disabled: 'Cellulular 2G is disabled',
            },
            minimumWifiSecurityLevel: {
                unspecified: 'Default',
                openNetworkSecurity: 'Fully open: can connect to all types of Wi-Fi',
                personalNetworkSecurity: 'Only personal networks allowed (WEP, WPA2-PSK)',
                enterpriseNetworkSecurity: 'Only enterprise EAP networks allowed',
                enterprise192NetworkSecurity: 'Only 192-bit enterprise EAP networks allowed',
            },
        },
        printingPolicy: {
            unspecified: 'Default',
            allow: 'Allow',
            disallow: 'Disallow',
        },
        mtePolicy: {
            unspecified: 'Default (user can choose)',
            userChoice: 'User can choose to enable or disable MTE',
            enforced: 'MTE is enabled on the device',
            disabled: 'MTE is disabled on the device',
        },
    },
};
