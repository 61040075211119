import { IApprovedLocations } from './ApprovedLocations';
import { ISessions } from './Sessions';
import { IPendingEmailUpdates } from './PendingEmailUpdates';
import { IConsumables } from './Consumables';
import { IMemberships } from './Memberships';
import { IBackupCodes } from './BackupCodes';
import { IResellers } from './Resellers';

export enum Genders {
    'UNKNOWN' = 'UNKNOWN',
    'MALE' = 'MALE',
    'FEMALE' = 'FEMALE',
    'NONBINARY' = 'NONBINARY',
}

export interface IUsers {
    createdAt: Date;
    updatedAt: Date;
    id: string;
    countryCode?: string;
    gender?: Genders;
    name: string;
    password?: string;
    hasPassword: boolean;
    prefersLanguage?: string;
    timezone?: string;
    twoFactorEnabled: boolean;
    twoFactorSecret?: string;
    passwordSchemeVersion: number;
    email: string;
    isVerified: boolean;
    onboardingCompleted: boolean;
    resellerId: string;
    reseller?: IResellers;
    approvedLocations?: IApprovedLocations[];
    sessions?: ISessions[];
    pendingEmailUpdates?: IPendingEmailUpdates[];
    consumables?: IConsumables[];
    memberships?: IMemberships[];
    backupCodes?: IBackupCodes[];
    phoneNumber?: string;
    preferredMembershipId?: string;
    preferredMembership?: IMemberships;
}
