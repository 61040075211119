export default {
    trialPeriod: {
        endsDate: 'Ends {0}',
        noTrial: 'No trial',
        endedDate: 'Ended {0}',
    },
    unpaidAmount: 'Amount: {0}',
    lastPaidAmount: 'Last invoice: {amount} ({date})',
    noStripeCustomerId: 'No Stripe customer ID',
};
