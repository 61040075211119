export default {
    title: 'Reports',
    text: 'Reports are generated in CSV format',
    item: 'report | reports',
    generateText: 'The table will update with the new report, you will also receive an email once the report is generated.',
    deleteDialog: {
        title: 'Delete report',
        text: {
            p1: 'Are you sure you want to delete the',
            p2: '{0} report',
            p3: 'created on',
            p4: 'from',
            p5: '?',
        },
    },
    notReversible:
        "This action is not reversible, and you'll have to generate it again if you change your mind. | This action is not reversible, and you'll have to generate them again if you change your mind.",
    bulkDeleteDialog: {
        title: 'Delete report | Delete reports',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'report from | reports from',
            p3: '?',
        },
    },
    multiMatchToDelete: 'Delete 1 report | Delete {count} reports',
    generating: 'Generating',
    noData: 'You have not generated any reports',
    unknownRequester: 'Unknown',
    searchFields: 'type',
};
