export default {
    nonComplianceDetails: 'Non-compliance details',
    thisDeviceIsAffectedBy: 'due to the following issue: | due to the following issues:',
    googleAdviceIs: "Google's advice is:",
    deviceIsCompliantBut: 'This device is compliant but has details that are non-compliant with the policy.',
    deviceHasNonCompliantDetails: 'This device has details that are non-compliant with the policy.',
    pleaseReviewThemBelow: 'Please review them below.',
    state: 'State:',
    deleting: 'Deleting',
    processOfRemoving: "Google's services are in the process of removing the device, this might take a little while",
    deviceSettings: 'Device settings',
    pleaseUpdate: ', please update device',
    softwareInfo: 'Software info',
    systemUpdateInfo: 'System update info',
    showSystemApps: 'Show system apps',
    noDataApps: 'You have not added any apps',
    hardwareInfo: 'Hardware info',
    networkInfo: 'Network info',
    telephonyInfo: 'Telephony info',
    memoryInfo: 'Memory info',
    memoryEvents: 'Memory events',
    powerManagementEvents: 'Power management events',
    noDataCommands: 'No commands issued for device',
    appsSearchFields: 'name or package',
    commandState: {
        success: 'Success',
        timedOutUnsuccessful: 'Timed out or unsuccessful',
        inProgress: 'In progress',
    },
    ManagementMode: {
        unspecified: 'Unspecified',
        deviceOwner: 'Device owner, policy has full control over device',
        profileOwner: 'Profile owner, policy has control over managed profile',
    },
    DeviceState: {
        unspecified: 'Unspecified',
        active: 'Active',
        disabled: 'Disabled',
        deleted: 'Deleted',
        provisioning: 'Provisioning',
        pendingDelete: 'Deleting',
    },
    NonComplianceReason: {
        unspecified: 'Unspecified',
        apiLevel: 'Setting not supported in the API level of the Android version running on the device.',
        managementMode: 'The management mode (profile owner, device owner, etc.) does not support the setting',
        userAction: 'User has not taken the required action to comply with setting',
        invalidValue: 'Setting has an invalid value',
        appNotInstalled: 'App required to implement policy is not installed',
        unsupported: 'Policy is not supported by the version of Android Device Policy on device',
        appInstalled: 'A blocked app is installed',
        pending: 'Pending, expected to be applied shortly',
        appIncompatible: 'App does not support the setting',
        appNotUpdated: 'App has not been updated to the minimum version specified by policy',
        deviceIncompatible: 'Device is incompatible with the policy requirements.',
    },

    MemoryEventType: {
        unspecified: 'Unspecified',
        ramMeasured: 'Measured free space in RAM',
        internalStorageMeasured: 'Measured free space in internal storage',
        externalStorageDetected: 'Detected new external storage',
        externalStorageRemoved: 'External storage was removed',
        externalStorageMeasured: 'Measured free space in external storage',
    },

    PowerManagementEventType: {
        unspecified: 'Unspecified',
        batteryLevelCollected: 'Measured battery level',
        powerConnected: 'Device started charging',
        powerDisconnected: 'Device stopped charging',
        batteryLow: 'Device entered low-power mode',
        batteryOkay: 'Device exited low-power mode',
        bootCompleted: 'Device booted',
        shutdown: 'Device shutdown',
    },

    EncryptionStatus: {
        unspecified: 'Unspecified',
        unsupported: 'Encryption not supported by device',
        inactive: 'Encryption is supported, but is not currently active',
        activating: 'Encryption is in the process of being activated',
        active: 'Encryption is active',
        activeDefaultKey: 'Encryption is active, but key is not set by the user',
        activePerUser: 'Encryption is active, and the key is tied to the user profile',
    },

    DevicePosture: {
        unspecified: 'Unspecified',
        secure: 'Device is secure',
        atRisk: 'Device may be at risk',
        potentiallyCompromised: 'Device may be compromised',
    },

    SecurityRisk: {
        unspecified: 'The device has an unknown security risk',
        unknownOs: 'The device is running an unknown OS',
        compromisedOs: 'The device is running a compromised OS',
        hardwareBackedFailed: 'Device does not have a strong guarantee of system integrity (Hardware-backed keystore, etc.)',
    },

    Ownership: {
        unspecified: 'Unspecified',
        companyOwned: 'Company-owned',
        personallyOwned: 'Personally-owned',
    },

    CommonCriteriaModeStatus: {
        unknown: 'Unknown',
        disabled: 'Common Criteria Mode is disabled',
        enabled: 'Common Criteria Mode is enabled',
    },

    InstallationFailureReason: {
        unspecified: 'Unspecified',
        unknown:
            "Unknown. Some potential reasons are: device doesn't have enough storage, network connection is unreliable, or the installation is taking longer than expected. The installation will be retried automatically",
        inProgress: 'Installation is still in progress',
        notfound: 'App was not found in Play Store',
        notCompatibleWithDevice: 'App is incompatible with the device',
        notApproved: 'App has not been approved by the admin',
        permissionsNotAccepted: 'App has new permissions that have not been accepted by the admin',
        notAvailableInCountry: "App is not available in the user's country",
        noLicensesRemaining: 'There are no licenses available to assign to the user',
        notEnrolled:
            'The enterprise is no longer enrolled with Managed Google Play or the admin has not accepted the latest Managed Google Play Terms of Service',
        userInvalid: 'User is no longer valid, it may have been deleted or disabled',
        netErrUnreliableConnection: "A network error on the user's device has prevented the install from succeeding",
        insufficientStorage: "The user's device does not have sufficient storage space to install the app",
    },

    SpecificNonComplianceReason: {
        unspecified: 'Unspecified',
        userConfirmationNeeded: 'User needs to confirm credentials by entering the screen lock',
        passwordExpired: 'The device or profile password has expired, requires user action',
        passwordNotSufficient: 'The device password does not meet password requirements, requires user action',
        wifiInvalid: 'There is an incorrect value in the ONC Wi-Fi configuration',
        wifiInvalidEnterpriseConfig: 'The enterprise Wi-Fi network is missing either the root CA or domain name',
        wifiApiLevel: 'The ONC Wi-Fi setting is not supported in the API level of the Android version running on the device.',
    },

    toGiB: '{0} GiB',

    ClearData: {
        title: 'Clear app data',
        confirmation: {
            p1: 'Are you sure you want to clear the data of',
            p2: 'application from the device',
            p3: '?',
        },
        clearConfirmButton: 'Yes, clear',
        error: 'Unable to clear app data, please try again',
    },
    userFacingTypeOptions: {
        unspecified: 'Unspecified',
        userFacing: 'User facing',
        notUserFacing: 'Not user facing',
    },
    ActiveRadioName: {
        Cellular: 'Cellular',
        WiFi: 'Wi-Fi',
    },

    lastHeartbeatTimeHelpText: 'Last time we received a ping from the device.',
    locationFeature:
        'Want to keep track of your devices? Our location request feature allows you to do just that, you can even configure the location periodically and see a history of the latest locations a device has had.',
    locationBYOD: "This device is enrolled in BYOD work profile mode, so we can't track the location of this device.",
    metadata: {
        title: 'Metadata as of {date}',
        info: 'We attempt to fetch new metadata from the device every 4 hours',
        error: 'Something went wrong while fetching device metadata. Please try again later.',
    },
    tabs: {
        general: 'General',
        software: 'Software',
        applications: 'Applications',
        hardware: 'Hardware',
        operations: 'Operations',
        location: 'Location',
        lostModeDetails: 'Lost Mode Details',
    },
    deviceLastKnownLocation: 'Last known location',
    deviceLastKnownBattery: 'Last known battery',
    failedAttempts: 'Failed attempts to unlock device',
    times: 'time | times',
    noLostDataYet: 'No lost mode data yet!',
};
