export default {
    domainSuffixMatch: {
        label: 'Server domain name match requirements',
        help: 'In the form of comma-space separated domain suffixes',
        validation: 'Domain suffixes should be separated by comma and space',
    },
    MACAddressRandomizationMode: {
        label: 'MAC randomization mode',
        help: 'Only applies to Android 13+ on all management modes.',
        options: {
            unset: 'Unset',
            hardware: 'Hardware',
            automatic: 'Automatic',
        },
        info: {
            p1: ' uses the factory MAC address when connecting to the network while',
            p2: ' lets Wi-Fi framework decide MAC randomization strategy.',
        },
    },
};
