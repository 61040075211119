export enum ImageLibLayout {
    LIST = 0,
    GALLERY = 1,
    CAROUSEL = 2,
    GRID = 3,
}

export enum LibraryType {
    IMAGES = 0,
    VIDEOS = 1,
    FILES = 2,
}

/**
 * Content Manager: openDefaults
 */
export enum OpenDefaults {
    LIBRARY_OPEN = 'LIBRARY_OPEN',
    LIBRARY_FORCED = 'LIBRARY_FORCED',
    NO_LIBRARY = 'NO_LIBRARY',
}

export enum FileType {
    IMAGE = 0,
    VIDEO = 1,
    FILE = 2,
}
