export default {
    title: 'Team members',
    item: 'member | members',
    text: 'Manage access to your team.',
    showAdd: {
        title: 'Add member',
    },
    showViewDialog: {
        title: 'View {0}',
    },
    showUpdate: {
        title: 'Configure {0}',
        text: {
            p1: 'You can change the role of',
            p2: ', who has the',
            p3: 'role and uses',
            p4: 'email.',
        },
    },
    showDeleteDialog: {
        title: 'Remove member',
        text: {
            p1: 'Are you sure you want to remove',
            p2: 'member with the role of',
            p3: 'from this team?',
        },
    },
    showBulkDeleteDialog: {
        title: 'Remove member | Remove members',
        text: {
            p1: 'Are you sure you want to remove',
            p2: 'member from | members from',
            p3: '? This member is: | ? These members are:',
        },
    },
    bulkAddDialog: {
        title: 'Add members via CSV',
        text: {
            p1: 'You can add multiple members through a CSV file, we will send you an email once the import has been completed.',
            p2: 'The minimum required fields are:',
            p3: 'name, email, and role',
            p4: '. Please note these are case-sensitive.',
        },
    },
    searchFields: 'name',
    noData: 'You have not added any members',
    matchToDelete: 'Remove {0}',
    multiMatchToDelete: 'Remove 1 member | Remove {count} members',
};
