export default {
    title: 'Locations',
    text: 'Locations involves creating virtual boundaries around specific geographic areas using GPS.',
    item: 'location | locations',
    noData: 'You have not added any locations',
    matchToDelete: 'Delete {0}',
    deleteDialog: {
        title: 'Delete {0}',
        bulkTitle: 'Remove location | Remove locations',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'from this team?',
            locations: '{0} locations',
        },
    },
    addLocation: 'Adding location',
    pan: 'Pan',
    drawCircle: 'Draw Circle',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    fitCircleInMap: 'Fit circle in map',
    addSuccess: 'Location has been added successfully.',
    updateSuccess: 'Location has been updated successfully.',
    locationNotFound: "Unable to retrieve location information. Either it's been deleted or you are not allowed to access it.",
    mapDialogTitle: 'Pick optimal radius for your geo-fence',
    mapDialogContent1:
        'For best results, the minimum radius of the geo-fence should be set between 100 - 150 meters. When Wi-Fi is available location accuracy is usually between 20 - 50 meters. When indoor location is available, the accuracy range can be as small as 5 meters. Unless you know indoor location is available inside the geofence, assume that Wi-Fi location accuracy is about 50 meters.',
    mapDialogContent2:
        "When Wi-Fi location isn't available (for example, when you are driving in rural areas) the location accuracy degrades. The accuracy range can be as large as several hundred meters to several kilometers. In cases like this, you should create geofences using a larger radius.",
    isAre: 'is | are',
    bulkDelete: 'There was an error deleting {0}, because {1} {2} associated with {3}. {4}',
    deleteError: "We couldn't delete {0}, because location is associated with {1}. {2}",
};
