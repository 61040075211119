export default {
    intro: 'We hope you have been enjoying the trial period!',
    billing: {
        p1: 'It will end in',
        p2: 'and has been leveraging features in the ',
        p3: 'plan.',
        p4: 'Make sure to set a payment in your',
        p5: "before the trial ends to continue uninterrupted, or if you'd prefer not to continue you may delete this team via ",
        p6: 'this page',
        p7: 'before the trial ends to continue uninterrupted.',
    },
    admin: {
        p1: 'It will end in',
        p2: ', so make sure to set up a payment in your',
    },
    notAdmin: {
        p1: 'It will end in',
        p2: ', so please reach out to a team administrator so they can set up a payment before trial ends to continue uninterrupted. Otherwise, the team will be set to read-only mode.',
        deleteTeam: 'If you do not wish to continue with us, you can contact a team administrator to delete the team.',
    },
    hasBillingPermission: {
        p1: 'before the trial ends to continue uninterrupted.',
    },
    lessThanAnHour: 'less than an hour',
    trialEnded: {
        intro: 'Your trial has ended. ',
        generic:
            'The team has been set as read-only in the meantime and your device enrolment tokens, and developer API access tokens have been removed. You have {0} days to take further action before your data is deleted.',
        admin: {
            p1: 'We hope you were able to enjoy our trial period features. To continue using our service, please complete setting up your subscription via ',
            p2: 'this page. ',
            p3: 'If you do not wish to continue with us, you can delete your team immediately via',
        },
        notAdmin: {
            p1: 'We hope you were able to enjoy our trial period features. To continue using our service, please contact a team administrator to set up a subscription and add a payment method or the team will be deleted on {0}. They can alternatively delete the team immediately.',
            p2: 'If you do not wish to continue with us, please contact ',
            deleteTeam: 'a team administrator and they can alternatively delete the team immediately.',
        },
        paymentDone: 'Note that there may be some processing time after adding a payment method before your billing information is updated.',
        contact: {
            p1: 'Feel free to contact us via our ',
            p2: 'for any inquiries.',
        },
    },
};
