export default {
    title: 'Policy components',
    item: 'component | components',
    text: 'Policy components are used to build policies. The benefit of components is the ability to reuse them across several policies, allowing bulk policy updates with minimum effort.',
    addDialog: {
        title: 'Add policy component',
    },
    duplicateDialog: {
        title: 'Duplicate {0}',
    },
    deleteDialog: {
        title: 'Delete {0}',
        text: {
            p1: 'Are you sure you want to delete',
            p2: '{0} policy component from this team?',
        },
    },
    viewDialog: {
        title: 'View {0}',
    },
    configureDialog: {
        title: 'Configure {0}',
    },
    bulkDeleteDialog: {
        title: 'Delete component | Delete components',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'component from | components from',
            p3: '? If used in a policy, the component will be replaced for the default one.',
        },
    },
    subtitle: '{0} component',
    noData: 'You have no policy components',
    matchToDelete: 'Delete {0}',
    multiMatchToDelete: 'Delete 1 component | Delete {count} components',
};
