export default {
    title: 'Users',
    item: 'user | users',
    noData: 'There are no users registered',
    text: 'These are the users registered under your reseller account.',
    resetPassword: {
        title: `Reset {name}'s password`,
        info: 'An email to {name} will be sent so that they can reset their password. They will receive it on their email ({email}) shortly.',
        error: 'Failed to send reset password email.',
    },
    viewTeams: {
        title: `{name}'s teams`,
    },
    disable2fa: {
        title: 'Disable 2FA for {name}',
        info: 'An email to {name} will be sent so that they can disable two-factor authentication. They will receive it on their email ({email}) shortly.',
        error: 'Failed to disable two-factor authentication.',
    },
    leaveTeam: {
        title: 'Remove {name} from team',
        info: {
            p1: 'You can remove',
            p2: 'from any team, as long as they are not the sole team owner.',
        },
        errorLoad: 'Failed to fetch teams',
        soleOwner: {
            p1: 'This user is the sole owner of the following teams:',
            p2: 'To remove the user from these teams, the teams have to be completely deleted.',
        },
        alert: {
            noTeam: "User isn't part of any teams.",
            cantBeRemoved: "{name} can't be removed from any teams because they are the sole owner of all of them.",
        },
        confirm: 'Are you sure you want to remove {name} from {teamCount} teams?',
        confirmText: 'Remove from {teamCount} team | Remove from {teamCount} teams',
    },
    verifiedStatus: {
        true: 'Verified',
        false: 'Pending',
    },
    teamOwner: {
        true: 'Owner of one or more teams',
        false: 'Not a team owner',
        info: {
            oneTeam: '{userName} is the owner of {team1}.',
            twoTeams: '{userName} is the owner of {team1} and {team2}.',
            moreTeams: '{userName} is the owner of {team1}, {team2} and {rest} more.',
        },
    },
};
