export default {
    title: 'Webhooks',
    item: 'webhook | webhooks',
    text: 'You can add event restrictions and a secret key by configuring the webhook after creating it.',
    addDialog: {
        title: 'Add webhook',
    },
    updateDialog: {
        title: 'Configure webhook',
    },
    viewDialog: {
        title: 'View webhook',
    },
    deleteDialog: {
        title: 'Delete webhook',
        text: {
            p1: 'Are you sure you want to delete your webhook with',
            p2: 'no events | 1 event | {count} events',
            p3: 'on',
            p4: 'URL? This action is not reversible, and this webhook will stop triggering immediately.',
        },
    },
    bulkDeleteDialog: {
        title: 'Delete webhook | Delete webhooks',
        text: 'Are you sure you want to delete 1 webhook? This action is not reversible, and this webhook will stop working immediately. | Are you sure you want to delete {count} webhooks? This action is not reversible, and these webhooks will stop working immediately.',
    },
    thisWebhookIs: 'This webhook is currently',
    thisWillMake: 'This will make your webhook be',
    noData: 'You have not created any webhooks',
    webhookSecretHelp: {
        p1: 'This will return a `signature` field in the message, this signature will be a base64 encoded hash using the HMAC SHA256 hashing algorithm  on the message `payload`,  with this secret as the verifying key.',
        p2: "We've partially hidden the current secret to protect your information, leave this field the same to keep secret unchanged.",
    },
    keepActive: 'Keep active',
    activate: 'Activate',
    enabledString: 'enabled',
    disabledString: 'disabled',
};
