export default {
    passwordLength: 'Code must be 6 digits or 44 characters for backup codes',
    passwordsShouldBeEqual: 'Passwords should be equal',
    wholeNumber: 'Input must be a positive integer',
    noFreeEmail: 'Only business email address can be used',
    phoneNumber: 'Only numbers and the following characters are allowed: + - ( )',
    noSpaces: 'Spaces are not allowed',
    onlyLowercase: 'Letters can only be lowercase',
    onlyAlphaNumericDash: 'Only lowercase alpha-numeric characters and hyphen are allowed',
    packageName: 'Only alpha-numeric characters, dot, and underscore are allowed',
    commaSpacePackageName: 'Package names can only contain letters, numbers and dots, they should be separated by comma and space',
    commaSpaceValidationMessage: 'Values should be separated by comma and space',
    commaSpaceValidationHelpText: 'In the form of comma-space separated values',
    GeneralPolicyComponentForm: {
        invalidSystemUpdate: 'Times should have at least 30 minutes of difference',
    },
    qrCodeLength: 'Code must have 6 characters',
    alphanumericMust: 'There must be at least one alpha-numeric character',
    fileTypeCSV: 'File must be of type csv',
    fileTypeImage: 'File must be of type image e.g. jpeg, png',
    boundEmailHelp: 'Feel free to take note of the binding email for future reference',
    noTrailingSpaces: 'Value is not allowed to start or end with a space.',
    domain: 'Domain must have a top-level domain with at least two characters, like .com',
    secondLevelDomain: `Domain must have a second-level domain with at least two characters, like 'google'`,
    fileSizeMax1GiB: 'File size needs to be less than 1GiB',
};
