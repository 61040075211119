
import { VueFormulateContext } from '@/util/vueformulate-context';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class ChipInput extends Vue {
    @Prop({ required: true }) context!: VueFormulateContext;
    @Prop({ default: false, type: Boolean }) disabled!: boolean;
    @Prop({ default: '', type: String }) placeholder!: string;

    currentInput = '';
    focusChipIndex = -1;

    get value() {
        if (Array.isArray(this.context.model)) {
            return this.context.model;
        }
        return [];
    }

    addChip() {
        if (this.disabled) return;
        const trimmedInput = this.currentInput.trim();
        if (trimmedInput && !this.value.includes(trimmedInput)) {
            this.context.model = [...this.value, trimmedInput];
            this.currentInput = '';
        }
    }

    removeChip(index: number) {
        if (this.disabled) return;
        const updatedChips = [...this.value];
        updatedChips.splice(index, 1);
        this.context.model = updatedChips;

        if (this.focusChipIndex === index) {
            this.focusChipIndex = -1;
        } else if (this.focusChipIndex > index) {
            this.focusChipIndex -= 1;
        }
    }

    handleFocusChipIndex(index: number) {
        this.focusChipIndex = index;
    }

    handleChipKeydown(_: number) {
        /** nothing to handle */
    }

    handleBackspace() {
        if (this.disabled) return;

        if (this.currentInput === '' && this.value.length > 0) {
            if (this.focusChipIndex > -1) {
                this.removeChip(this.focusChipIndex);
            } else {
                this.focusChipIndex = this.value.length - 1;
            }
        }
    }

    handleArrowLeft() {
        if (this.disabled) return;

        if (this.currentInput === '') {
            if (this.focusChipIndex === -1) {
                this.focusChipIndex = this.value.length - 1;
            } else if (this.focusChipIndex > 0) {
                this.focusChipIndex -= 1;
            }
        }
    }

    handleArrowRight() {
        if (this.disabled) return;

        if (this.currentInput === '') {
            if (this.focusChipIndex === -1) {
                this.focusChipIndex = 0;
            } else if (this.focusChipIndex < this.value.length - 1) {
                this.focusChipIndex += 1;
            }
        }
    }

    focusChipInput() {
        (this.$refs.input as HTMLInputElement).focus();
    }
}
