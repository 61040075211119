export default {
    goToBilling: 'Go to billing',
    countDayTrialEnded: 'Your 1-day trial has ended | Your {count}-day trial has ended',
    trialEnded: 'Your trial has ended',
    description: {
        admin: 'We hope you were able to enjoy our trial period features, now all you need to do is complete setting up your subscription to continue using our service.',

        notAdmin:
            'We hope you were able to enjoy our trial period features, please contact a team administrator so they can finish setting up a subscription to continue using our service.',
        generic: {
            p1: 'The team has been set as read-only in the meantime and your device enrollment tokens, and developer API access tokens have been removed. You have', // X days
            p2: 'to take further action before your data is deleted.',
        },
    },
    paymentDone: 'There may be some processing time after adding a payment method before your billing information is updated.',
};
