/**
 * AMAPI Policy Enums
 */

export enum Duration {
    MIN_VALUE = 'MIN_VALUE',
    MAX_VALUE = 'MAX_VALUE',
    ZERO = 'ZERO',
}

/**
 * Lock-screen features that can be individualy disabled
 */
export enum KeyguardDisabledFeature {
    KEYGUARD_DISABLED_FEATURE_UNSPECIFIED = 'KEYGUARD_DISABLED_FEATURE_UNSPECIFIED',
    CAMERA = 'CAMERA',
    NOTIFICATIONS = 'NOTIFICATIONS',
    UNREDACTED_NOTIFICATIONS = 'UNREDACTED_NOTIFICATIONS',
    TRUST_AGENTS = 'TRUST_AGENTS',
    DISABLE_FINGERPRINT = 'DISABLE_FINGERPRINT',
    DISABLE_REMOTE_INPUT = 'DISABLE_REMOTE_INPUT',
    FACE = 'FACE',
    IRIS = 'IRIS',
    BIOMETRICS = 'BIOMETRICS',
    SHORTCUTS = 'SHORTCUTS',
    ALL_FEATURES = 'ALL_FEATURES',
}

/**
 * Basic permission policies
 */
export enum PermissionPolicy {
    PERMISSION_POLICY_UNSPECIFIED = 'PERMISSION_POLICY_UNSPECIFIED',
    PROMPT = 'PROMPT',
    GRANT = 'GRANT',
    DENY = 'DENY',
}

/**
 * Control the permissions available for microphone access
 */
export enum MicrophoneAccess {
    MICROPHONE_ACCESS_UNSPECIFIED = 'MICROPHONE_ACCESS_UNSPECIFIED',
    MICROPHONE_ACCESS_USER_CHOICE = 'MICROPHONE_ACCESS_USER_CHOICE',
    MICROPHONE_ACCESS_DISABLED = 'MICROPHONE_ACCESS_DISABLED',
    MICROPHONE_ACCESS_ENFORCED = 'MICROPHONE_ACCESS_ENFORCED',
}

/**
 * Control the permissions available for camera access
 */
export enum CameraAccess {
    CAMERA_ACCESS_UNSPECIFIED = 'CAMERA_ACCESS_UNSPECIFIED',
    CAMERA_ACCESS_USER_CHOICE = 'CAMERA_ACCESS_USER_CHOICE',
    CAMERA_ACCESS_DISABLED = 'CAMERA_ACCESS_DISABLED',
    CAMERA_ACCESS_ENFORCED = 'CAMERA_ACCESS_ENFORCED',
}

/**
 * Define encryption level
 */
export enum EncryptionPolicy {
    ENCRYPTION_POLICY_UNSPECIFIED = 'ENCRYPTION_POLICY_UNSPECIFIED',
    ENABLED_WITHOUT_PASSWORD = 'ENABLED_WITHOUT_PASSWORD',
    ENABLED_WITH_PASSWORD = 'ENABLED_WITH_PASSWORD',
}

/**
 * Define password complexity
 */
export enum PasswordQuality {
    PASSWORD_QUALITY_UNSPECIFIED = 'PASSWORD_QUALITY_UNSPECIFIED',
    BIOMETRIC_WEAK = 'BIOMETRIC_WEAK',
    SOMETHING = 'SOMETHING',
    NUMERIC = 'NUMERIC',
    NUMERIC_COMPLEX = 'NUMERIC_COMPLEX',
    ALPHABETIC = 'ALPHABETIC',
    ALPHANUMERIC = 'ALPHANUMERIC',
    COMPLEX = 'COMPLEX',
}

/**
 * Scope for passwords (Apply to device or work-profile)
 */
export enum PasswordPolicyScope {
    SCOPE_UNSPECIFIED = 'SCOPE_UNSPECIFIED',
    SCOPE_DEVICE = 'SCOPE_DEVICE',
    SCOPE_PROFILE = 'SCOPE_PROFILE',
}

/**
 * Control how often password must be re-entered on a device
 */
export enum RequirePasswordUnlock {
    REQUIRE_PASSWORD_UNLOCK_UNSPECIFIED = 'REQUIRE_PASSWORD_UNLOCK_UNSPECIFIED',
    USE_DEFAULT_DEVICE_TIMEOUT = 'USE_DEFAULT_DEVICE_TIMEOUT',
    REQUIRE_EVERY_DAY = 'REQUIRE_EVERY_DAY',
}

/**
 * Control whether work and personal profile can have a unified lock, or require individual unlocking
 */
export enum UnifiedLockSettings {
    UNIFIED_LOCK_SETTINGS_UNSPECIFIED = 'UNIFIED_LOCK_SETTINGS_UNSPECIFIED',
    ALLOW_UNIFIED_WORK_AND_PERSONAL_LOCK = 'ALLOW_UNIFIED_WORK_AND_PERSONAL_LOCK',
    REQUIRE_SEPARATE_WORK_LOCK = 'REQUIRE_SEPARATE_WORK_LOCK',
}

/**
 * Scope of access blocking due to device policy non-compliance
 */
export enum BlockScope {
    BLOCK_SCOPE_UNSPECIFIED = 'BLOCK_SCOPE_UNSPECIFIED',
    BLOCK_SCOPE_WORK_PROFILE = 'BLOCK_SCOPE_WORK_PROFILE',
    BLOCK_SCOPE_DEVICE = 'BLOCK_SCOPE_DEVICE',
}

/**
 * Policy for controlling untrusted apps (Often installed from outside Play Store)
 */
export enum UntrustedAppsPolicy {
    UNTRUSTED_APPS_POLICY_UNSPECIFIED = 'UNTRUSTED_APPS_POLICY_UNSPECIFIED',
    DISALLOW_INSTALL = 'DISALLOW_INSTALL',
    ALLOW_INSTALL_IN_PERSONAL_PROFILE_ONLY = 'ALLOW_INSTALL_IN_PERSONAL_PROFILE_ONLY',
    ALLOW_INSTALL_DEVICE_WIDE = 'ALLOW_INSTALL_DEVICE_WIDE',
}

/**
 * Control enforcement of Google Play Protect verification
 */
export enum GooglePlayProtectVerifyApps {
    GOOGLE_PLAY_PROTECT_VERIFY_APPS_UNSPECIFIED = 'GOOGLE_PLAY_PROTECT_VERIFY_APPS_UNSPECIFIED',
    VERIFY_APPS_ENFORCED = 'VERIFY_APPS_ENFORCED',
    VERIFY_APPS_USER_CHOICE = 'VERIFY_APPS_USER_CHOICE',
}

/**
 * Control access to developer settings on device
 */
export enum DeveloperSettings {
    DEVELOPER_SETTINGS_UNSPECIFIED = 'DEVELOPER_SETTINGS_UNSPECIFIED',
    DEVELOPER_SETTINGS_DISABLED = 'DEVELOPER_SETTINGS_DISABLED',
    DEVELOPER_SETTINGS_ALLOWED = 'DEVELOPER_SETTINGS_ALLOWED',
}

/**
 * Policy enforcement of stricter security (Common Criteria for Information Technology Security Evaluation)
 */
export enum CommonCriteriaMode {
    COMMON_CRITERIA_MODE_UNSPECIFIED = 'COMMON_CRITERIA_MODE_UNSPECIFIED',
    COMMON_CRITERIA_MODE_DISABLED = 'COMMON_CRITERIA_MODE_DISABLED',
    COMMON_CRITERIA_MODE_ENABLED = 'COMMON_CRITERIA_MODE_ENABLED',
}

/**
 * Control access to location usage
 */
export enum LocationMode {
    LOCATION_MODE_UNSPECIFIED = 'LOCATION_MODE_UNSPECIFIED',
    LOCATION_USER_CHOICE = 'LOCATION_USER_CHOICE',
    LOCATION_ENFORCED = 'LOCATION_ENFORCED',
    LOCATION_DISABLED = 'LOCATION_DISABLED',
}

/**
 * Control access to power buttons in kiosk mode
 */
export enum PowerButtonActions {
    POWER_BUTTON_ACTIONS_UNSPECIFIED = 'POWER_BUTTON_ACTIONS_UNSPECIFIED',
    POWER_BUTTON_AVAILABLE = 'POWER_BUTTON_AVAILABLE',
    POWER_BUTTON_BLOCKED = 'POWER_BUTTON_BLOCKED',
}

/**
 * Control if system error dialogs are shown when in kiosk mode
 */
export enum SystemErrorWarnings {
    SYSTEM_ERROR_WARNINGS_UNSPECIFIED = 'SYSTEM_ERROR_WARNINGS_UNSPECIFIED',
    ERROR_AND_WARNINGS_ENABLED = 'ERROR_AND_WARNINGS_ENABLED',
    ERROR_AND_WARNINGS_MUTED = 'ERROR_AND_WARNINGS_MUTED',
}

/**
 * Control which navigation features are enabled in kiosk mode
 */
export enum SystemNavigation {
    SYSTEM_NAVIGATION_UNSPECIFIED = 'SYSTEM_NAVIGATION_UNSPECIFIED',
    NAVIGATION_ENABLED = 'NAVIGATION_ENABLED',
    NAVIGATION_DISABLED = 'NAVIGATION_DISABLED',
    HOME_BUTTON_ONLY = 'HOME_BUTTON_ONLY',
}

/**
 * Control if system info and notifications are disabled in kiosk mode
 */
export enum StatusBar {
    STATUS_BAR_UNSPECIFIED = 'STATUS_BAR_UNSPECIFIED',
    NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED = 'NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED',
    NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED = 'NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED',
    SYSTEM_INFO_ONLY = 'SYSTEM_INFO_ONLY',
}

/**
 * Control access to device settings in kiosk mode
 */
export enum DeviceSettings {
    DEVICE_SETTINGS_UNSPECIFIED = 'DEVICE_SETTINGS_UNSPECIFIED',
    SETTINGS_ACCESS_ALLOWED = 'SETTINGS_ACCESS_ALLOWED',
    SETTINGS_ACCESS_BLOCKED = 'SETTINGS_ACCESS_BLOCKED',
}

/**
 * Control how automatic system updates are handled
 */
export enum SystemUpdateType {
    SYSTEM_UPDATE_TYPE_UNSPECIFIED = 'SYSTEM_UPDATE_TYPE_UNSPECIFIED',
    AUTOMATIC = 'AUTOMATIC',
    WINDOWED = 'WINDOWED',
    POSTPONE = 'POSTPONE',
}

/**
 * Control how automatic app updates are handled
 */
export enum AppAutoUpdatePolicy {
    APP_AUTO_UPDATE_POLICY_UNSPECIFIED = 'APP_AUTO_UPDATE_POLICY_UNSPECIFIED',
    CHOICE_TO_THE_USER = 'CHOICE_TO_THE_USER',
    NEVER = 'NEVER',
    WIFI_ONLY = 'WIFI_ONLY',
    ALWAYS = 'ALWAYS',
}

export enum AutoUpdateMode {
    AUTO_UPDATE_MODE_UNSPECIFIED = 'AUTO_UPDATE_MODE_UNSPECIFIED',
    AUTO_UPDATE_DEFAULT = 'AUTO_UPDATE_DEFAULT',
    AUTO_UPDATE_POSTPONED = 'AUTO_UPDATE_POSTPONED',
    AUTO_UPDATE_HIGH_PRIORITY = 'AUTO_UPDATE_HIGH_PRIORITY',
}

/**
 * Control how Play Store app lists work (Allow or disallow)
 */
export enum PlayStoreMode {
    PLAY_STORE_MODE_UNSPECIFIED = 'PLAY_STORE_MODE_UNSPECIFIED',
    WHITELIST = 'WHITELIST',
    BLACKLIST = 'BLACKLIST',
}

/**
 * Control how personal Play Store app lists work (Allow or disallow)
 */
export enum PersonalPlayStoreMode {
    PLAY_STORE_MODE_UNSPECIFIED = 'PLAY_STORE_MODE_UNSPECIFIED',
    ALLOWLIST = 'ALLOWLIST',
    BLOCKLIST = 'BLOCKLIST',
}

/**
 * Control device charging modes
 */
export enum BatteryPluggedMode {
    BATTERY_PLUGGED_MODE_UNSPECIFIED = 'BATTERY_PLUGGED_MODE_UNSPECIFIED',
    AC = 'AC',
    USB = 'USB',
    WIRELESS = 'WIRELESS',
}

/**
 * Control app install type
 */
export enum InstallType {
    INSTALL_TYPE_UNSPECIFIED = 'INSTALL_TYPE_UNSPECIFIED',
    PREINSTALLED = 'PREINSTALLED',
    FORCE_INSTALLED = 'FORCE_INSTALLED',
    BLOCKED = 'BLOCKED',
    AVAILABLE = 'AVAILABLE',
    REQUIRED_FOR_SETUP = 'REQUIRED_FOR_SETUP',
    KIOSK = 'KIOSK',
}

/**
 * Control personal app install type
 */
export enum PersonalInstallType {
    INSTALL_TYPE_UNSPECIFIED = 'INSTALL_TYPE_UNSPECIFIED',
    BLOCKED = 'BLOCKED',
    AVAILABLE = 'AVAILABLE',
}

/**
 * Control Android Device Policy scopes delegated to another app
 */
export enum DelegatedScope {
    DELEGATED_SCOPE_UNSPECIFIED = 'DELEGATED_SCOPE_UNSPECIFIED',
    CERT_INSTALL = 'CERT_INSTALL',
    MANAGED_CONFIGURATIONS = 'MANAGED_CONFIGURATIONS',
    BLOCK_UNINSTALL = 'BLOCK_UNINSTALL',
    PERMISSION_GRANT = 'PERMISSION_GRANT',
    PACKAGE_ACCESS = 'PACKAGE_ACCESS',
    ENABLE_SYSTEM_APP = 'ENABLE_SYSTEM_APP',
    NETWORK_ACTIVITY_LOGS = 'NETWORK_ACTIVITY_LOGS',
    SECURITY_LOGS = 'SECURITY_LOGS',
}

/**
 * Control allowing work and personal apps to be connected
 */
export enum ConnectedWorkAndPersonalApp {
    CONNECTED_WORK_AND_PERSONAL_APP_UNSPECIFIED = 'CONNECTED_WORK_AND_PERSONAL_APP_UNSPECIFIED',
    CONNECTED_WORK_AND_PERSONAL_APP_DISALLOWED = 'CONNECTED_WORK_AND_PERSONAL_APP_DISALLOWED',
    CONNECTED_WORK_AND_PERSONAL_APP_ALLOWED = 'CONNECTED_WORK_AND_PERSONAL_APP_ALLOWED',
}

/**
 * Controls whether an app is exempt from the `alwaysOnVpnPackage.lockdownEnabled` setting.
 */
export enum AlwaysOnVpnLockdownExemption {
    ALWAYS_ON_VPN_LOCKDOWN_EXEMPTION_UNSPECIFIED = 'ALWAYS_ON_VPN_LOCKDOWN_EXEMPTION_UNSPECIFIED',
    VPN_LOCKDOWN_ENFORCED = 'VPN_LOCKDOWN_ENFORCED',
    VPN_LOCKDOWN_EXEMPTION = 'VPN_LOCKDOWN_EXEMPTION',
}

/**
 * Define WiFi network security type
 */
export enum WiFiSecurity {
    'WPA-PSK' = 'WPA-PSK',
    'WEP-PSK' = 'WEP-PSK',
    'WPA-EAP' = 'WPA-EAP',
    'WEP-8021X' = 'WEP-8021X',
    'WPA3-Enterprise_192' = 'WPA3-Enterprise_192',
    'None' = 'None',
}

/**
 * Define network certificate type
 */
export enum NetworkCertificateType {
    Client = 'Client',
    Server = 'Server',
    Authority = 'Authority',
}

/**
 * Define network certificate security type
 */
export enum NetworkCertificateOuter {
    'EAP-AKA' = 'EAP-AKA',
    'EAP-TLS' = 'EAP-TLS',
    'EAP-TTLS' = 'EAP-TTLS',
    'EAP-SIM' = 'EAP-SIM',
    'PEAP' = 'PEAP',
}

/**
 * Define network certificate security type
 */
export enum NetworkCertificateInner {
    PAP = 'PAP',
    MSCHAP = 'MSCHAP',
    MSCHAPV2 = 'MSCHAPV2',
    GTC = 'GTC',
    CHAP = 'CHAP',
}

/**
 * Managed config primitive types
 */
export enum ManagedPropertyType {
    BOOL = 'BOOL',
    STRING = 'STRING',
    INTEGER = 'INTEGER',
    CHOICE = 'CHOICE',
    MULTISELECT = 'MULTISELECT',
    HIDDEN = 'HIDDEN',
    BUNDLE = 'BUNDLE',
    BUNDLE_ARRAY = 'BUNDLE_ARRAY',
}

/**
 * Define enforcement for automatic network updated time on device
 */
export enum AutoDateAndTimeZone {
    AUTO_DATE_AND_TIME_ZONE_UNSPECIFIED = 'AUTO_DATE_AND_TIME_ZONE_UNSPECIFIED',
    AUTO_DATE_AND_TIME_ZONE_USER_CHOICE = 'AUTO_DATE_AND_TIME_ZONE_USER_CHOICE',
    AUTO_DATE_AND_TIME_ZONE_ENFORCED = 'AUTO_DATE_AND_TIME_ZONE_ENFORCED',
}

/**
 * Allow sharing contacts between work and personal profile
 */
export enum ShowWorkContactsInPersonalProfile {
    SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_UNSPECIFIED = 'SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_UNSPECIFIED',
    SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED = 'SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED',
    SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_ALLOWED = 'SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_ALLOWED',
    SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED_EXCEPT_SYSTEM = 'SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED_EXCEPT_SYSTEM',
}

/**
 * Allow sharing copy-paste clipboard content between work and personal profile
 */
export enum CrossProfileCopyPaste {
    CROSS_PROFILE_COPY_PASTE_UNSPECIFIED = 'CROSS_PROFILE_COPY_PASTE_UNSPECIFIED',
    COPY_FROM_WORK_TO_PERSONAL_DISALLOWED = 'COPY_FROM_WORK_TO_PERSONAL_DISALLOWED',
    CROSS_PROFILE_COPY_PASTE_ALLOWED = 'CROSS_PROFILE_COPY_PASTE_ALLOWED',
}

/**
 * Define what level of data sharing between work and personal profile are allowed
 */
export enum CrossProfileDataSharing {
    CROSS_PROFILE_DATA_SHARING_UNSPECIFIED = 'CROSS_PROFILE_DATA_SHARING_UNSPECIFIED',
    CROSS_PROFILE_DATA_SHARING_DISALLOWED = 'CROSS_PROFILE_DATA_SHARING_DISALLOWED',
    DATA_SHARING_FROM_WORK_TO_PERSONAL_DISALLOWED = 'DATA_SHARING_FROM_WORK_TO_PERSONAL_DISALLOWED',
    CROSS_PROFILE_DATA_SHARING_ALLOWED = 'CROSS_PROFILE_DATA_SHARING_ALLOWED',
}

/**
 * Allow work profile installed apps to add widgets to phone homescreen
 */
export enum WorkProfileWidgetsDefault {
    WORK_PROFILE_WIDGETS_DEFAULT_UNSPECIFIED = 'WORK_PROFILE_WIDGETS_DEFAULT_UNSPECIFIED',
    WORK_PROFILE_WIDGETS_DEFAULT_ALLOWED = 'WORK_PROFILE_WIDGETS_DEFAULT_ALLOWED',
    WORK_PROFILE_WIDGETS_DEFAULT_DISALLOWED = 'WORK_PROFILE_WIDGETS_DEFAULT_DISALLOWED',
}

/** Allow setting a preferred network (WiFi or mobile) for work profile traffic */
export enum PreferentialNetworkService {
    PREFERENTIAL_NETWORK_SERVICE_UNSPECIFIED = 'PREFERENTIAL_NETWORK_SERVICE_UNSPECIFIED',
    PREFERENTIAL_NETWORK_SERVICE_DISABLED = 'PREFERENTIAL_NETWORK_SERVICE_DISABLED',
    PREFERENTIAL_NETWORK_SERVICE_ENABLED = 'PREFERENTIAL_NETWORK_SERVICE_ENABLED',
}

/**
 * Device activity log types
 */
export enum LogType {
    LOG_TYPE_UNSPECIFIED = 'LOG_TYPE_UNSPECIFIED',
    SECURITY_LOGS = 'SECURITY_LOGS',
    NETWORK_ACTIVITY_LOGS = 'NETWORK_ACTIVITY_LOGS',
}

/**
 * Enable home screen widgets for specific work profile application
 */
export enum WorkProfileWidgets {
    WORK_PROFILE_WIDGETS_UNSPECIFIED = 'WORK_PROFILE_WIDGETS_UNSPECIFIED',
    WORK_PROFILE_WIDGETS_ALLOWED = 'WORK_PROFILE_WIDGETS_ALLOWED',
    WORK_PROFILE_WIDGETS_DISALLOWED = 'WORK_PROFILE_WIDGETS_DISALLOWED',
}

/**
 * Custom Mambo Enums
 */

/**
 * Define the multi-app kiosk type for use
 */
export enum KioskType {
    ADVANCED = 'ADVANCED',
    NATIVE = 'NATIVE',
}

/**
 * Define Mambo heartbeat interval
 */
export enum HeartbeatInterval {
    // NEVER = '0',
    EVERY_HOUR = '60',
    EVERY_4_HOURS = '240',
    EVERY_8_HOURS = '480',
    EVERY_12_HOURS = '720',
    EVERY_DAY = '1440',
}

/**
 * Define Mambo location upload interval
 */
export enum LocationUploadInterval {
    NEVER = '0',
    EVERY_HOUR = '60',
    EVERY_4_HOURS = '240',
    EVERY_8_HOURS = '480',
    EVERY_12_HOURS = '720',
    EVERY_DAY = '1440',
}

/**
 * Controls what can be transferred over USB
 */
export enum UsbDataAccess {
    USB_DATA_ACCESS_UNSPECIFIED = 'USB_DATA_ACCESS_UNSPECIFIED',
    ALLOW_USB_DATA_TRANSFER = 'ALLOW_USB_DATA_TRANSFER',
    DISALLOW_USB_FILE_TRANSFER = 'DISALLOW_USB_FILE_TRANSFER',
    DISALLOW_USB_DATA_TRANSFER = 'DISALLOW_USB_DATA_TRANSFER',
}

/**
 * Control access to WiFi configuration
 */
export enum ConfigureWifi {
    CONFIGURE_WIFI_UNSPECIFIED = 'CONFIGURE_WIFI_UNSPECIFIED',
    ALLOW_CONFIGURING_WIFI = 'ALLOW_CONFIGURING_WIFI',
    DISALLOW_ADD_WIFI_CONFIG = 'DISALLOW_ADD_WIFI_CONFIG',
    DISALLOW_CONFIGURING_WIFI = 'DISALLOW_CONFIGURING_WIFI',
}

/**
 * Allow WiFi Direct
 */
export enum WifiDirectSettings {
    WIFI_DIRECT_SETTINGS_UNSPECIFIED = 'WIFI_DIRECT_SETTINGS_UNSPECIFIED',
    ALLOW_WIFI_DIRECT = 'ALLOW_WIFI_DIRECT',
    DISALLOW_WIFI_DIRECT = 'DISALLOW_WIFI_DIRECT',
}

/**
 * Allow wireless tethering
 */
export enum TetheringSettings {
    TETHERING_SETTINGS_UNSPECIFIED = 'TETHERING_SETTINGS_UNSPECIFIED',
    ALLOW_ALL_TETHERING = 'ALLOW_ALL_TETHERING',
    DISALLOW_WIFI_TETHERING = 'DISALLOW_WIFI_TETHERING',
    DISALLOW_ALL_TETHERING = 'DISALLOW_ALL_TETHERING',
}

/**
 * Control WiFi state
 */
export enum WifiState {
    WIFI_STATE_UNSPECIFIED = 'WIFI_STATE_UNSPECIFIED',
    WIFI_STATE_USER_CHOICE = 'WIFI_STATE_USER_CHOICE',
    WIFI_ENABLED = 'WIFI_ENABLED',
    WIFI_DISABLED = 'WIFI_DISABLED',
}

/**
 * Control airplane mode state
 */
export enum AirplaneModeState {
    AIRPLANE_MODE_STATE_UNSPECIFIED = 'AIRPLANE_MODE_STATE_UNSPECIFIED',
    AIRPLANE_MODE_USER_CHOICE = 'AIRPLANE_MODE_USER_CHOICE',
    AIRPLANE_MODE_DISABLED = 'AIRPLANE_MODE_DISABLED',
}

/**
 * Control ultra-wide band state
 */
export enum UltraWidebandState {
    ULTRA_WIDEBAND_STATE_UNSPECIFIED = 'ULTRA_WIDEBAND_STATE_UNSPECIFIED',
    ULTRA_WIDEBAND_USER_CHOICE = 'ULTRA_WIDEBAND_USER_CHOICE',
    ULTRA_WIDEBAND_DISABLED = 'ULTRA_WIDEBAND_DISABLED',
}

/**
 * Control cellular 2G state
 */
export enum CellularTwoGState {
    CELLULAR_TWO_G_STATE_UNSPECIFIED = 'CELLULAR_TWO_G_STATE_UNSPECIFIED',
    CELLULAR_TWO_G_USER_CHOICE = 'CELLULAR_TWO_G_USER_CHOICE',
    CELLULAR_TWO_G_DISABLED = 'CELLULAR_TWO_G_DISABLED',
}

/**
 * User facing type
 */
export enum UserFacingType {
    USER_FACING_TYPE_UNSPECIFIED = 'USER_FACING_TYPE_UNSPECIFIED',
    NOT_USER_FACING = 'NOT_USER_FACING',
    USER_FACING = 'USER_FACING',
}

export enum MACAddressRandomizationMode {
    HARDWARE = 'Hardware',
    AUTOMATIC = 'Automatic',
}

export enum MinimumWifiSecurityLevel {
    MINIMUM_WIFI_SECURITY_LEVEL_UNSPECIFIED = 'MINIMUM_WIFI_SECURITY_LEVEL_UNSPECIFIED',
    OPEN_NETWORK_SECURITY = 'OPEN_NETWORK_SECURITY',
    PERSONAL_NETWORK_SECURITY = 'PERSONAL_NETWORK_SECURITY',
    ENTERPRISE_NETWORK_SECURITY = 'ENTERPRISE_NETWORK_SECURITY',
    ENTERPRISE_BIT192_NETWORK_SECURITY = 'ENTERPRISE_BIT192_NETWORK_SECURITY',
}

export enum PrintingPolicy {
    PRINTING_POLICY_UNSPECIFIED = 'PRINTING_POLICY_UNSPECIFIED',
    PRINTING_DISALLOWED = 'PRINTING_DISALLOWED',
    PRINTING_ALLOWED = 'PRINTING_ALLOWED',
}

export enum CredentialProviderPolicyDefault {
    CREDENTIAL_PROVIDER_POLICY_DEFAULT_UNSPECIFIED = 'CREDENTIAL_PROVIDER_POLICY_DEFAULT_UNSPECIFIED',
    CREDENTIAL_PROVIDER_DEFAULT_DISALLOWED = 'CREDENTIAL_PROVIDER_DEFAULT_DISALLOWED',
    CREDENTIAL_PROVIDER_DEFAULT_DISALLOWED_EXCEPT_SYSTEM = 'CREDENTIAL_PROVIDER_DEFAULT_DISALLOWED_EXCEPT_SYSTEM',
}

export enum CredentialProviderPolicy {
    CREDENTIAL_PROVIDER_POLICY_UNSPECIFIED = 'CREDENTIAL_PROVIDER_POLICY_UNSPECIFIED',
    CREDENTIAL_PROVIDER_ALLOWED = 'CREDENTIAL_PROVIDER_ALLOWED',
}

export enum NetworkTypeConstraint {
    NETWORK_TYPE_CONSTRAINT_UNSPECIFIED = 'NETWORK_TYPE_CONSTRAINT_UNSPECIFIED',
    INSTALL_ON_ANY_NETWORK = 'INSTALL_ON_ANY_NETWORK',
    INSTALL_ONLY_ON_UNMETERED_NETWORK = 'INSTALL_ONLY_ON_UNMETERED_NETWORK',
}

export enum ChargingConstraint {
    CHARGING_CONSTRAINT_UNSPECIFIED = 'CHARGING_CONSTRAINT_UNSPECIFIED',
    CHARGING_NOT_REQUIRED = 'CHARGING_NOT_REQUIRED',
    INSTALL_ONLY_WHEN_CHARGING = 'INSTALL_ONLY_WHEN_CHARGING',
}

export enum DeviceIdleConstraint {
    DEVICE_IDLE_CONSTRAINT_UNSPECIFIED = 'DEVICE_IDLE_CONSTRAINT_UNSPECIFIED',
    DEVICE_IDLE_NOT_REQUIRED = 'DEVICE_IDLE_NOT_REQUIRED',
    INSTALL_ONLY_WHEN_DEVICE_IDLE = 'INSTALL_ONLY_WHEN_DEVICE_IDLE',
}

export enum MtePolicy {
    MTE_POLICY_UNSPECIFIED = 'MTE_POLICY_UNSPECIFIED',
    MTE_USER_CHOICE = 'MTE_USER_CHOICE',
    MTE_ENFORCED = 'MTE_ENFORCED',
    MTE_DISABLED = 'MTE_DISABLED',
}

export enum ClientCertType {
    KeyPairAlias = 'KeyPairAlias',
    Ref = 'Ref',
}

export enum WifiSsidPolicyType {
    WIFI_SSID_POLICY_TYPE_UNSPECIFIED = 'WIFI_SSID_POLICY_TYPE_UNSPECIFIED',
    WIFI_SSID_DENYLIST = 'WIFI_SSID_DENYLIST',
    WIFI_SSID_ALLOWLIST = 'WIFI_SSID_ALLOWLIST',
}

export enum UserControlSettings {
    USER_CONTROL_SETTINGS_UNSPECIFIED = 'USER_CONTROL_SETTINGS_UNSPECIFIED',
    USER_CONTROL_ALLOWED = 'USER_CONTROL_ALLOWED',
    USER_CONTROL_DISALLOWED = 'USER_CONTROL_DISALLOWED',
}

export enum ScreenBrightnessMode {
    SCREEN_BRIGHTNESS_MODE_UNSPECIFIED = 'SCREEN_BRIGHTNESS_MODE_UNSPECIFIED',
    BRIGHTNESS_USER_CHOICE = 'BRIGHTNESS_USER_CHOICE',
    BRIGHTNESS_AUTOMATIC = 'BRIGHTNESS_AUTOMATIC',
    BRIGHTNESS_FIXED = 'BRIGHTNESS_FIXED',
}

export enum ScreenTimeoutMode {
    SCREEN_TIMEOUT_MODE_UNSPECIFIED = 'SCREEN_TIMEOUT_MODE_UNSPECIFIED',
    SCREEN_TIMEOUT_USER_CHOICE = 'SCREEN_TIMEOUT_USER_CHOICE',
    SCREEN_TIMEOUT_ENFORCED = 'SCREEN_TIMEOUT_ENFORCED',
}
