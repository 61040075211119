export default {
    feedback: 'Feedback',
    dialogTitle: 'Give us feedback',
    dialogSubtitle: {
        text: 'Use this form to submit feedback to the {0} team. Note this is not a support channel, please reach out for support queries via {0}. ',
        learnMore: 'Learn more about this in our ',
        link: 'feedback form support article.',
    },
    type: {
        bug: 'Bug',
        feature: 'New Feature',
        enhancement: 'Improvement',
    },
};
