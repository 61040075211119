export default {
    title: 'Device Credit for {0}',
    balance: 'Credit Balance',
    balanceSubtitleReseller: `As a reseller, you can allocate credits to reduce a team's monthly bill by offsetting the total charges for enrolled devices.
Please note that negative balance is treated as zero balance.`,
    balanceSubtitleTeam: 'You are billed monthly for enrolled devices, but credits can reduce your bill by covering a portion of the total device charges.',
    invoiceWarningReseller: `If a team has devices enrolled before receiving a certain number of credits,
they will still be billed for those devices for the current billing cycle.
For example, if a team already has 100 devices enrolled and receives a credit for 50 devices during the middle of the current billing cycle,
they will still be charged for 100 devices this time. For the next billing cycle, these credits will reduce their bill.
However, if the team enrolls new devices in the current cycle after receiving credits, they will still see the billing reduced.
For example, if they enroll 10 more devices, they won't be billed for 110 devices, but for 100 devices only.`,
    invoiceWarningTeam: `If you have devices enrolled before receiving a certain number of credits,
you will still be billed for those devices for the current billing cycle.
For example, if you already have 100 devices enrolled and receive a credit for 50 devices during
the middle of the current billing cycle, you will still be charged for 100 devices this time.
For the next billing cycle, these credits will reduce your bill.
However, if you enroll new devices in the current cycle after receiving credits,
you will still see the billing reduced. For example, if you enroll 10 more devices,
you won't be billed for 110 devices, but for 100 devices only.`,
    description: 'You can give credits for device enrollment to {0}. Entering a negative number will deduct credits from the team.',
    expiredAlert: 'Credit of {0} devices has expired (it is not counted towards the final balance)',
};
