export default {
    title: 'Enterprise',
    item: 'document | documents',
    subtitle1: 'Contact info',
    subtitle2: 'Terms and conditions',
    showAddDialog: {
        title: 'Add new terms and conditions',
        text: 'Manage the terms and conditions for your enterprise.',
    },
    showViewDialog: {
        title: 'View terms and conditions document',
    },
    showEditDialog: {
        title: 'Edit terms and conditions document',
    },
    showDeleteDialog: {
        title: 'Delete terms and conditions document',
        text: 'Are you sure you want to delete this terms and conditions document? It contains the following title:',
    },
    noData: 'You have not created any terms and conditions documents',
};
