export default {
    signupPrompt: "Don't have an account?",
    logo: 'Logo',
    noMatch: 'No match could be found',
    ifYouDo: 'If you are happy to continue, please type the following in the field below:',
    ifYouAre: 'If you are, please fill the following field with',
    active: 'Active',
    inactive: 'Inactive',
    enabled: 'Enabled',
    disabled: 'Disabled',
    seconds: 'Second(s)',
    minutes: 'Minute(s)',
    hours: 'Hour(s)',
    days: 'Day(s)',
    day: 'day',
    secondsCount: '1 second | {count} seconds',
    minutesCount: '1 minute | {count} minutes',
    never: 'Never',
    default: 'Default',
    thisActionIsNotReversible: 'This action is not reversible.',
    hasIncompleteFields: 'Has incomplete fields',
    current: 'Current',
    new: 'New',
    empty: 'Empty',
    required: 'Required',
    optional: 'Optional',
    noSelection: 'No selection',
    nothingSelected: 'Nothing selected',
    notApplicable: 'N/A',
    dashboard: 'Dashboard',
    reseller: 'Reseller',
    admin: 'Admin',
    previewPolicyComponent: "Preview {0} policy's components",
    unknownComponentType: "The type {0} isn't a known Policy Component type",
    search: 'Search',
    searchBy: 'Search by {0}',
    updateDataAvailable: 'Updated data is available',
    resultsAmount: '1 result | {count} results',
    itemsShown: 'Items shown',
    jumpToPage: 'Jump to page',
    jumpToPageNumber: 'Jump to page number {count}',
    columns: 'Columns',
    columnsLimit: 'You can display 1-{0} columns',
    noColumnsMatch: 'No columns match the search',
    youHave: 'You have',
    itemSelected: '{0} selected',
    all: 'all',
    nameNotAvailable: 'This name is already in use, please use a different one.',
    and: '{0} and {1}',
    noMatchesFound: 'No matches found',
    typeToSearch: 'Type to search',
    teamDelete: 'Team is being deleted',
    queuedDelete: 'Policy is queued to delete',
    noData: 'No data has been added',
    emptyFilter: 'Your filter is empty',
    selectPageItems: 'Select page items',
    noSelectablesInPage: 'No selectable items in this page',
    unselectPageItems: 'Unselect page items',
    selectItem: 'Select item',
    notSelectable: 'Not selectable',
    sort: 'Sort',
    selected: 'Selected',
    noOtherTeam: 'Not part of any other teams',
    countDashDays: '{count}-day',
    countDays: '1 day | {count} days',
    countHours: '1 hour | {count} hours',
    none: 'None',
    viewActions: 'View more actions',
    descending: 'Descending',
    ascending: 'Ascending',
    or: 'OR',
    sinceDate: 'Since {date}',
    onDate: 'On {date}',
    off: '{0} off',
    upgradePlan: {
        p1: 'Check out',
        p2: 'to upgrade your plan.',
    },
    policyBeingApplied: 'Policy change to {0} has been submitted and is awaiting device feedback.',
    ignoreForNow: 'Ignore for now',
    noItemsToSelect: 'No items to select in this page',
    upcomingAmount: 'Amount:',
    notReported: 'Not reported',
    betaFeature: 'Beta feature',
    storageInfo: '{gbUsed}GiB used of {gbTotal}GiB ({gbFree}GiB free)',
};
