export default {
    title: 'Resellers',
    text: 'Resellers will be able to register a domain for white label usage of Balloon.',
    dialog: {
        add: {
            title: 'Create new reseller',
        },
        update: {
            title: 'Update reseller',
        },
    },
    viewTeamsDialog: {
        title: "{reseller}'s teams",
    },
    searchFields: 'name',
    noData: 'No resellers found',
    platformSelect: {
        mambo: 'Mambo',
        rhino: 'Rhino Configure',
    },
};
