import VuexPersist from 'vuex-persist';
import Cookies from 'js-cookie';
import psl, { ParsedDomain } from 'psl';
import store from '@/store';
import storage from '@/util/storage';

/**
 * Register cookie persistence for auth tokens module
 */
export const cookie = new VuexPersist({
    key: 'balloon-state',
    restoreState: (key, _storage) => JSON.parse(Cookies.get(key) ?? '{}'),
    saveState: (key: string, state: Record<string, any>, _storage) => {
        // Get the current app domain
        const URL = psl.parse(window.location.hostname);
        if (URL.error) throw new Error('Invalid domain');

        // Remove watcher from auth state
        if (state?.auth?.tokenWatcherInterval) {
            delete state.auth.tokenWatcherInterval;
        }

        // Set state
        Cookies.set(key, JSON.stringify(state), {
            expires: 7,
            sameSite: 'Lax',
            secure: process.env.NODE_ENV === 'production',
            domain: (URL as ParsedDomain).domain as string,
        });
    },
    modules: ['auth'],
}).plugin(store);

/**
 * Register local persistence for caching of user data on this domain
 */
export const local = new VuexPersist({
    key: 'balloon-state',
    restoreState: (key, _storage) => storage.get(key),
    saveState: (key, state, _storage) => storage.set(key, state),
    modules: ['reseller', 'team', 'user'],
}).plugin(store);
