export default {
    // Generic
    teamSetToReadOnly:
        'Your team has been set to read-only mode in the meantime. Your device enrollment tokens and developer API access tokens have been removed. Devices and data are unnaffected.',
    contactSupport: {
        p1: 'Feel free to contact us via our',
        // support page]
        p2: 'for any concerns.',
    },

    pausedNonPaymentFlow: 'Your team has been given an additional grace period to resolve your billing issues.',

    // Billing issues
    invalidPaymentMethod: {
        withBillingPermission: {
            p1: 'Your team does not have a valid payment method, please visit your',
            // billing portal
            p2: 'to amend this at your earliest convenience.',
        },
        withoutBillingPermission:
            'Your team does not have a valid payment method, please alert a team administrator so they can ammend this as soon as possible.',
    },
    brokenSubscription: "We have found an issue with your team's subscription and we are working to repair this as soon as possible.",
    unpaidInvoices: {
        // Only owners and users with billing permissions
        from_0_to_15: {
            p1: 'Your team has an unpaid invoice, please visit your',
            // billing portal
            p2: 'to review and/or amend your payment information as soon as possible.',
        },
        from_15_to_30: {
            banner: {
                withBillingPermission: {
                    p1: "Your team's invoice is overdue, please visit your",
                    // billing portal
                    p2: 'to review and/or amend your payment information as soon as possible. Otherwise, your team will be set to read-only mode in {daysUntilReadOnly} days.',
                },
                noBillingPermission: "Your team's invoice is overdue, please alert a team administrator to avoid service disruption.",
            },
            fullScreen: {
                withBillingPermission: {
                    title: 'Your team has unpaid invoices',
                    text: "Your team's invoice is overdue, please review and/or amend your payment information as soon as possible. Otherwise, your team will be set to read-only mode in {daysUntilReadOnly} days.",
                    button: 'Go to billing',
                },
                noBillingPermission: {
                    title: 'Your team has unpaid invoices',
                    text: "Your team's invoice is overdue, please alert a team administrator to avoid service disruption.",
                },
            },
        },
        // Team set to read-nonly
        from_30_to_60: {
            banner: {
                withBillingPermission: {
                    p1: 'Your team has one or more invoices severely overdue.',
                    // teamSetToReadOnly message
                    p2: 'Please visit your',
                    // billing portal
                    p3: 'to review and/or amend your payment information to regain full access to the service.',
                },
                noBillingPermission: {
                    p1: 'Your team has one or more invoices severely overdue.',
                    // teamSetToReadOnly message
                    p2: 'Please alert a team administrator to regain full access to our service.',
                },
            },
            fullScreen: {
                withBillingPermission: {
                    title: 'Your team has unpaid invoices',
                    text: {
                        p1: 'Your team has one or more invoices severely overdue.',
                        // teamSetToReadOnly message
                        p2: 'Please amend your payment information to regain full access to the service.',
                    },
                    button: 'Go to billing',
                },
                noBillingPermission: {
                    title: 'Your team has unpaid invoices',
                    text: {
                        p1: 'Your team has one or more invoices severely overdue.',
                        // teamSetToReadOnly message
                        p2: 'Please alert a team administrator to regain full access to our service.',
                    },
                },
            },
        },
        // Team scheduled for deletion
        from_60: {
            banner: {
                withBillingPermission: {
                    p1: 'Your team has one or more invoices critically overdue, this has caused your team to be scheduled for deletion. You have {daysUntilDeletion} days left before the deletion happens.',
                    // teamSetToReadOnly message
                    p2: 'Please visit your',
                    // billing portal
                    p3: 'to review and/or amend your payment information to regain full access to our service.',
                },
                noBillingPermission: {
                    p1: 'Your team has one or more invoices critically overdue, this has caused your team to be scheduled for deletion. You have {daysUntilDeletion} days left before the deletion happens.',
                    // teamSetToReadOnly message
                    p2: 'Please alert a team administrator to regain full access to our service.',
                },
            },
            fullScreen: {
                withBillingPermission: {
                    title: 'Your team has unpaid invoices',
                    text: {
                        p1: 'Your team has one or more invoices critically overdue, this has caused your team to be scheduled for deletion. You have {daysUntilDeletion} days left before the deletion happens.',
                        // teamSetToReadOnly message
                        p2: 'Please visit your billing portal to review and/or amend your payment information to regain full access to our service.',
                    },
                    button: 'Go to billing',
                },
                noBillingPermission: {
                    title: 'Your team has unpaid invoices',
                    text: {
                        p1: 'Your team has one or more invoices critically overdue, this has caused your team to be scheduled for deletion. You have {daysUntilDeletion} days left before the deletion happens.',
                        // teamSetToReadOnly message
                        p2: 'Please alert a team administrator to regain full access to our service.',
                    },
                },
            },
        },
    },
    cancelledSubscription: {
        // Only owners and users with billing permissions
        from_0_to_15: {
            p1: 'Your team has a cancelled subscription, please visit your',
            // billing portal
            p2: 'to review and/or amend this as soon as possible.',
        },
        from_15_to_30: {
            banner: {
                withBillingPermission: {
                    p1: 'Your team has a cancelled subscription, please visit your',
                    // billing page
                    p2: 'to review and/or amend this as soon as possible. Otherwise, your team will be set to read-only mode in {daysUntilReadOnly} days.',
                },
                noBillingPermission: 'Your team has a cancelled subscription, please alert a team administrator to avoid service disruption.',
            },
            fullScreen: {
                withBillingPermission: {
                    title: 'Your team has a cancelled subscription',
                    text: 'Your team has a cancelled subscription, please review and/or amend this as soon as possible. Otherwise, your team will be set to read-only mode in {daysUntilReadOnly} days.',
                    button: 'Go to billing',
                },
                noBillingPermission: {
                    title: 'Your team has a cancelled subscription',
                    text: "Your team's invoice is overdue, please alert a team administrator to avoid service disruption.",
                },
            },
        },
        // Team set to read-nonly
        from_30_to_60: {
            banner: {
                withBillingPermission: {
                    p1: 'Your team has had a cancelled subscription for a long period of time.',
                    // teamSetToReadOnly message
                    p2: 'Please visit your',
                    // billing page
                    p3: 'to review and/or amend this to regain full access to our service.',
                },
                noBillingPermission: {
                    p1: 'Your team has had a cancelled subscription for a long period of time.',
                    // teamSetToReadOnly message
                    p2: 'Please alert a team administrator to regain full access to our service.',
                },
            },
            fullScreen: {
                withBillingPermission: {
                    title: 'Your team has a cancelled subscription',
                    text: {
                        p1: 'Your team has had a cancelled subscription for a long period of time.',
                        // teamSetToReadOnly message
                        p2: 'Please amend your payment information to regain full access to our service.',
                    },
                    button: 'Go to billing',
                },
                noBillingPermission: {
                    title: 'Your team has a cancelled subscription',
                    text: {
                        p1: 'Your team has had a cancelled subscription for a long period of time.',
                        // teamSetToReadOnly message
                        p2: 'Please alert a team administrator to regain full access to our service.',
                    },
                },
            },
        },
        // Team scheduled for deletion
        from_60: {
            banner: {
                withBillingPermission: {
                    p1: 'Your team has not had a valid subscription for more than 60 days, your team is now scheduled for deletion. You have {daysUntilDeletion} days left before the deletion happens.',
                    // teamSetToReadOnly message
                    p2: 'Please visit your',
                    // billing page
                    p3: 'to review and/or amend your payment information to regain full access to the service.',
                },
                noBillingPermission: {
                    p1: 'Your team has not had a valid subscription for more than 60 days, your team is now scheduled for deletion. You have {daysUntilDeletion} days left before the deletion happens.',
                    // teamSetToReadOnly message
                    p2: 'Please alert a team administrator to regain full access to the service.',
                },
            },
            fullScreen: {
                withBillingPermission: {
                    title: 'Your team has a cancelled subscription',
                    text: {
                        p1: 'Your team has not had a valid subscription for more than 60 days, your team is now scheduled for deletion. You have {daysUntilDeletion} days left before the deletion happens.',
                        // teamSetToReadOnly message
                        p2: 'Please visit your billing portal to review and/or amend your payment information to regain full access to the service.',
                    },
                    button: 'Go to billing',
                },
                noBillingPermission: {
                    title: 'Your team has a cancelled subscription',
                    text: {
                        p1: 'Your team has not had a valid subscription for more than 60 days, your team is now scheduled for deletion. You have {daysUntilDeletion} days left before the deletion happens.',
                        // teamSetToReadOnly message
                        p2: 'Please alert a team administrator to regain full access to the service.',
                    },
                },
            },
        },
    },
    emptySubscription: {
        // Only owners and users with billing permissions
        from_0_to_15: {
            p1: "Your team doesn't have a subscription set, please visit your",
            // billing portal
            p2: 'to review and/or amend this as soon as possible.',
        },
        from_15_to_30: {
            banner: {
                withBillingPermission: {
                    p1: "Your team doesn't have a subscription set, please visit your",
                    // billing page
                    p2: 'to review and/or amend this as soon as possible. Otherwise, your team will be set to read-only mode in {daysUntilReadOnly} days.',
                },
                noBillingPermission: "Your team doesn't have a subscription set, please alert a team administrator to avoid service disruption.",
            },
            fullScreen: {
                withBillingPermission: {
                    title: "Your team doesn't have a subscription set",
                    text: "Your team doesn't have a subscription set, please review and/or amend this as soon as possible. Otherwise, your team will be set to read-only mode in {daysUntilReadOnly} days.",
                    button: 'Go to billing',
                },
                noBillingPermission: {
                    title: "Your team doesn't have a subscription set",
                    text: "Your team doesn't have a subscription set, please alert a team administrator to avoid service disruption.",
                },
            },
        },
        // Team set to read-nonly
        from_30_to_60: {
            banner: {
                withBillingPermission: {
                    p1: "Your team doesn't have a subscription set, this has been going on for a long period of time.",
                    // teamSetToReadOnly message
                    p2: 'Please visit your',
                    // billing page
                    p3: 'to review and/or amend this to regain full access to the service.',
                },
                noBillingPermission: {
                    title: "Your team doesn't have a subscription set, this has been going on for a long period of time.",
                    // teamSetToReadOnly message
                    p2: 'Please alert a team administrator to regain full access to the service.',
                },
            },
            fullScreen: {
                withBillingPermission: {
                    title: "Your team doesn't have a subscription set",
                    text: {
                        p1: "Your team doesn't have a subscription set, this has been going on for a long period of time.",
                        // teamSetToReadOnly message
                        p2: 'Please amend your payment information to regain full access to the service.',
                    },
                    button: 'Go to billing',
                },
                noBillingPermission: {
                    title: "Your team doesn't have a subscription set",
                    text: {
                        p1: "Your team doesn't have a subscription set, this been going on for a long period of time.",
                        // teamSetToReadOnly message
                        p2: 'Please alert a team administrator to regain full access to the service.',
                    },
                },
            },
        },
        // Team scheduled for deletion
        from_60: {
            banner: {
                withBillingPermission: {
                    p1: 'Your team has not had a valid subscription for more than 60 days, your team is now scheduled for deletion. You have {daysUntilDeletion} days left before the deletion happens.',
                    // teamSetToReadOnly message
                    p2: 'Please visit your',
                    // billing page
                    p3: 'to review and/or amend your payment information to regain full access to the service.',
                },
                noBillingPermission: {
                    p1: 'Your team has not had a valid subscription for more than 60 days, your team is now scheduled for deletion. You have {daysUntilDeletion} days left before the deletion happens.',
                    // teamSetToReadOnly message
                    p2: 'Please alert a team administrator to regain full access to the service.',
                },
            },
            fullScreen: {
                withBillingPermission: {
                    title: "Your team doesn't have a subscription set",
                    text: {
                        p1: 'Your team has not had a valid subscription for more than 60 days, your team is now scheduled for deletion. You have {daysUntilDeletion} days left before the deletion happens.',
                        // teamSetToReadOnly message
                        p2: 'Please visit your',
                        // billing page
                        p3: 'to review and/or amend your payment information to regain full access to the service.',
                    },
                    button: 'Go to billing',
                },
                noBillingPermission: {
                    title: "Your team doesn't have a subscription set",
                    text: {
                        p1: 'Your team has not had a valid subscription for more than 60 days, your team is now scheduled for deletion. You have {daysUntilDeletion} days left before the deletion happens.',
                        // teamSetToReadOnly message
                        p2: 'Please alert a team administrator to regain full access to the service.',
                    },
                },
            },
        },
    },
};
