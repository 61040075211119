export default {
    title: 'Welcome {0}',
    text: 'Dashboard information updates every 30 minutes.',
    deviceCount: 'Devices enrolled',
    activeDevicesCount: 'Active devices',
    deviceState: 'Device state',
    nonCompliantDeviceCount: 'Non-compliant',
    osCount: 'Device OS',
    modelCount: 'Device model',
    teamCount: 'Teams managed',
    securityPatchLevelCount: 'Security patch level',
    activeRadioCount: 'Active radio',
    deviceBrandCount: 'Device brand',
    updateStatusCount: 'Device update status',
    subtitle: 'Get started',
    noTeamsUnderThisReseller: 'You have no teams under this reseller.',
    guide: {
        p1: "Add more owners or members through your team's",
        p2: 'to see charts and other relevant information.',
        p3: 'Use',
        p4: 'to create a',
        p5: 'You can upgrade your plan in',
        p6: 'to gain access to features like',
    },
    noTeam: {
        title: 'Create your own team',
        text: {
            p1: 'Get started by going to your',
            p2: 'to create a team.',
            p3: "You can also join other's teams by an invitation.",
        },
    },
    chartsMenu: 'Charts',
    noCharts: "No charts to display. Why not select some and we'll draw them right away?",
};
