export default {
    title: 'Teams',
    item: 'team | teams',
    teamCount: '1 team | {count} teams',
    noData: 'No teams found',
    text: 'You can create a new team using a unique slug for it.',
    bindEnterpriseDialog: {
        title: 'Bind AMAPI enterprise to team',
    },
    importPolicies: {
        title: 'Import policies',
    },
    devicePolicyUpdate: {
        title: 'Update device policies',
    },
    readOnlyAction: {
        canNotChangeDueToPendingDelete: "You can't change the state of this team, the deletion process needs to be cancelled first.",
        currentlyInactive:
            'has an inactive read-only mode, meaning their members have standard access. Are you sure you want to set the team to read-only mode?',
        areYouSureDeactivateReadOnly: "Are you sure you want to unset the team's read-only mode?",
        activateReadOnly:
            'This will immediately cause the team to be in read-only mode. Team-members will not be able to do any actions on pages but will still have view access to their data. | This will immediately cause these teams to be in read-only mode. Team-members will not be able to do any actions on pages but will still have view access to their data.',
        deactivateReadOnly:
            'This will allow team-members within the team to do actions like: add, configure, update, etc. Please be sure you want to make this change. | This will allow team-members within these teams to do actions like: add, configure, update, etc. Please be sure you want to make this change.',
    },
    readOnlyReason: {
        pendingDelete: 'is set to read-only mode due to it being in process of deletion.',
        invalidPaymentMethod: 'is set to read-only mode due to having no payment method or an invalid one.',
        unpaidInvoices: 'is set to read-only mode due to having unpaid invoices.',
        manual: 'was manually set to read-only mode.',
    },
    bulkReadOnlyAction: {
        youHave: 'You have',
        teamsSelected: 'selected, please select the read-only state you for all of them.',
        thisChange: 'This change will be immediate, are you sure you want to apply it?',
    },
    deleteAction: {
        title: 'Delete {teamName}',
        text: {
            teamOk: {
                p1: "Once you proceed with the delete, the team's subscription will be immediately paused to prevent further charges, and a 30-day countdown will begin. The deletion can be cancelled at any time in this period of time but not by team members.",
                p2: "Once the process starts, team owners will be the only ones to have access to the team's data in a view-only mode, this is reverted if the team deletion is cancelled. Any invoices must be paid before the deletion is fulfilled.",
                p3: "After 30 days, the team's data and its Android Enterprise bind will be fully deleted and all devices still enrolled will factory reset.",
            },
            onTrial: {
                p1: 'Team is currently on trial. All data associated will be irreversible and immediately deleted, with no option to restore. The enterprise bind will be deleted and any enrolled devices will be reset automatically.',
                p2: 'As the team is currently on trial, there are no charges associated with the team or removing it at this time.',
            },
            onPostTrial: {
                p1: 'Team is currently on post-trial (they ended their trial period without any payment method set). All data associated will be irreversible and immediately deleted, with no option to restore. The enterprise bind will be deleted and any enrolled devices will be reset automatically.',
                p2: 'As the team is currently on the post-trial flow, there are no charges associated with the team or removing it at this time.',
            },
            onboardingPending: {
                p1: 'Team has their onboarding pending. All data associated will be irreversible and immediately deleted, with no option to restore.',
                p2: 'As the team never finished onboarding, there are no charges associated with the team or removing it at this time.',
            },
        },
        matchToDelete: 'Delete {teamName}',
    },
    cancelDeleteAction: {
        title: 'Cancel {teamName} deletion',
        text: 'Are you sure you want to cancel the deletion of this team?',
        resellerCannotCancel:
            'The deletion of this team was triggered by an admin, so only them are allowed to cancel it, please reach out if this is a mistake.',
        matchToCancelDelete: 'Cancel {teamName} deletion',
    },
    viewBillingHistoryAction: {
        title: "{team}'s billing history",
    },
    viewOwnersAction: {
        title: "{team}'s owners",
    },
    viewTeamsAction: {
        title: "{reseller}'s teams",
    },
    readOnlyStateDisplay: {
        active: 'Active',
        inactive: 'Inactive',
    },
    readOnlyOptionsSelect: {
        activate: 'Activate read-only mode',
        deactivate: 'Deactivate read-only mode',
    },
    manualBilling: {
        text: 'will be set to manual billing mode. This will exempt the team from any checks regarding invoices, payment methods, and other automated billing flows.',
        title: 'Set manual billing',
        confirmation: 'Set manual billing',
    },
    stripeBilling: {
        text: 'will be set to automated (Stripe) billing mode. This will include the team in all checks regarding invoices, payment methods, and other automated billing flows.',
        title: 'Set automated billing',
        confirmation: 'Set automated billing',
    },
    error: {
        add_400: {
            p1: 'Please set up some plans on',
            p2: 'before creating a team.',
        },
        add_409: 'Slug is in use, please set a different one.',
    },
    // Text exclusive for reseller's table
    reseller: {
        text: 'These are the teams registered under your reseller account.',
    },
    nonPaymentFlow: {
        changeConfirmation: {
            restart: 'Restart flow',
            pause: 'Pause flow',
        },
        rowAlert: 'Non-payment flow has been paused since {date}',
        action: {
            pause: {
                label: 'Pause non-payment flow',
                p1: 'Pausing the non-payment flow will cause the team-members to have full access to the platform as if there were no billing issues. You can revert this at any time.',
                p2: 'Are you sure you want to pause the non-payment flow of {teamName}?',
            },
            restart: {
                label: 'Restart non-payment flow',
                p1: "Restarting the non-payment flow will cause the team to go back into the non-payment flow where, if they don't sort out their billing issues, in 30 days the team will get set to read-only and in 90 the team will be fully deleted.",
                p2: 'Are you sure you want to restart the non-payment flow of {teamName}?',
            },
        },
    },
    report: {
        info: 'This report will be generated with the applied search and filters you currently have on the table.',
        success: {
            p1: "The request has been sent, you can go to your team's",
            reportsPage: 'reports page',
            p2: "to download the report once it's been generated.",
        },
        error: 'Unable to generate report, please try again',
    },
    teamPendingDelete: '{teamName} is in process of being deleted. Deletion requested on: {date}.',
    teamPendingDeleteWithRequester: '{teamName} is in process of being deleted. Deletion requested on: {date} by {requester} ({requesterEmail}).',
    deletionRequesterType: {
        admin: 'an admin',
        member: 'a team member',
        reseller: 'the reseller',
    },
    onboardingCompletedOptions: {
        true: 'Completed',
        false: 'Incomplete',
    },
    extendTrialPeriod: {
        title: 'Extend Trial Period for {team}',
        error: 'Unable to extend trial period. Please try again.',
    },
    extendTrial: {
        extendBy7Days: 'Extend by 7 days',
        extendBy15Days: 'Extend by 15 days',
        extendBy30Days: 'Extend by 30 days',
        setCustomDate: 'Set custom date',
        selectDate: 'Select a date',
    },
    TrialPeriod: {
        title: 'Current Trial End:',
        startDate: 'Start Date:',
        endDate: 'End Date:',
        chooseExtensionPeriod: 'Choose Extension Period',
        notAvailable: 'Trial end date is not set',
        newDate: 'Trial will now end on',
    },
    changeNotificationTypes: {
        title: 'Pub/Sub settings for {teamName}',
        description: 'To learn more about Pub/Sub integration with Android Management API,',
        linkName: 'click here.',
    },
};
