export default {
    featureLabels: {
        branding: 'Custom branding',
        locationRequest: 'Finding a device',
        broadcastMessage: 'Broadcasting messages to devices',
        advancedKiosk: 'Supercharged kiosk',
        geofencing: 'Geofencing',
        remoteControl: 'Remote control',
        devicePossession: 'Device possession',
    },
    billingWarning: {
        validPaymentMethods: "Your team doesn't have a valid payment method, please add one in the",
        unpaidInvoices: {
            p1: 'You have an unpaid invoice, please go the',
            p2: 'to review and/or amend your payment information at your earliest convenience.',
        },
    },
    billingPortal: 'Billing Portal',
};
