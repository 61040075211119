/**
 * AMAPI iFrame required features
 */
export enum IframeFeature {
    PLAY_SEARCH = 'PLAY_SEARCH',
    PRIVATE_APPS = 'PRIVATE_APPS',
    WEB_APPS = 'WEB_APPS',
    STORE_BUILDER = 'STORE_BUILDER',
    MANAGED_CONFIGURATIONS = 'MANAGED_CONFIGURATIONS',
    ZERO_TOUCH = 'ZERO_TOUCH_CUSTOMER_MANAGEMENT',
}
