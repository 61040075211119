import twoFa from './2fa.en';
import forgot from './forgot.en';
import login from './login.en';
import recover from './recover.en';
import register from './register.en';
import token from './token.en';
import billing from './billing.en';
import reports from './reports.en';
import accessTokens from './accessTokens.en';
import webhooks from './webhooks.en';
import components from './components.en';
import fileManager from './fileManager.en';
import devices from './devices.en';
import enrollments from './enrollments.en';
import groups from './groups.en';
import policies from './policies.en';
import locations from './locations.en';
import onboarding from './onboarding.en';
import teamData from './teamData.en';
import teamEnterprise from './teamEnterprise.en';
import members from './members.en';
import roles from './roles.en';
import teamSettings from './teamSettings.en';
import adminDashboard from './adminDashboard.en';
import adminPayments from './adminPayments.en';
import adminResellers from './adminResellers.en';
import adminUsers from './adminUsers.en';
import adminFusionAuthConfig from './adminFusionAuthConfig.en';
import usersData from './usersData.en';
import usersPasswords from './usersPasswords.en';
import usersProfile from './usersProfile.en';
import usersSessions from './usersSessions.en';
import usersTeams from './usersTeams.en';
import page404 from './page404.en';
import page401 from './page401.en';
import indexDashboard from './indexDashboard.en';
import pageNew from './new.en';
import setup2fa from './setup2fa.en';
import support from './support.en';
import resellersUsers from './resellersUsers.en';
import pageMaintenance from './pageMaintenance.en';
import billingHistory from './billingHistory.en';
import offlineCompliance from './offlineCompliance.en';
import idpToken from './idpToken.en';

export default {
    twoFa,
    forgot,
    login,
    recover,
    register,
    token,
    billing,
    reports,
    accessTokens,
    webhooks,
    components,
    fileManager,
    devices,
    enrollments,
    groups,
    policies,
    locations,
    onboarding,
    teamData,
    teamEnterprise,
    members,
    roles,
    teamSettings,
    adminDashboard,
    adminPayments,
    adminResellers,
    adminUsers,
    adminFusionAuthConfig,
    usersData,
    usersPasswords,
    usersProfile,
    usersSessions,
    usersTeams,
    page404,
    page401,
    indexDashboard,
    pageNew,
    setup2fa,
    support,
    resellersUsers,
    pageMaintenance,
    billingHistory,
    offlineCompliance,
    idpToken,
};
