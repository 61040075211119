export default {
    title: 'Groups',
    text: 'Grouping refers to the process of organizing multiple devices into a set or group based on certain criteria or functionalities.',
    item: 'group | groups',
    addGroup: 'Add Group',
    deleteGroup: 'Delete Group',
    editGroup: 'Edit Group',
    noGroupsData: 'You have not added any groups',
    addGroupDialog: {
        title: 'Add New Group',
    },
    changeDialog: {
        title: 'Change policy of {0}',
        text: {
            p1: 'The group currently has the policy named',
            p2: 'applied.',
        },
    },
    enforceDialog: {
        title: 'Enforce policy of {0}',
        text: {
            p1: 'Are you sure you want to apply the ',
            p2: ' policy across all the devices within the group?',
        },
        error: 'Unable to apply the policy, please try again.',
    },
    matchToDelete: 'Delete {0}',
    deleteDialog: {
        title: 'Delete {0}',
        bulkTitle: 'Remove group | Remove groups',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'from this team?',
            groups: '{0} groups',
        },
    },
    deleteHelp: 'Choose new group where to move the devices in this group(s)',
    errors: {
        duplicateName: 'A group with that name already exists, please set a different one.',
        add: "The group couldn't be added, please try again.",
    },
    headers: {
        name: 'Name',
        associatedPolicy: 'Associated Policy',
        description: 'Description',
    },
    label: {
        name: 'Name',
        policyId: 'Policy Id',
        description: 'Description',
    },
};
