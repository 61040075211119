export enum ReportType {
    /** Basic PDF report */
    ASSETS_BASIC_PDF = 0,
    /** Detailed PDF report (All fields) */
    ASSETS_DETAILED_PDF,
    /** Basic CSV report */
    ASSETS_BASIC_CSV,
    /** Reseller Teams + Billing Info report */
    TEAM_AND_BILLING,
    /** App version CSV report */
    APP_VERSION_REPORT,
}
